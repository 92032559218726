import React, {useEffect, useState} from "react";
import '../../Styles/Feedback.scss';
import Layout from '../../components/Layout';
import { authService } from '../../services/auth.service';
import { useFormik} from 'formik';
import checkMark from '../../assets/icons/check-mark.svg';
import { Rate } from 'antd';
import { useUser } from '../../contexts/userContext';
import girlContact from '../../assets/webp_images/girl_contact.webp';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';

const Feedback = () => {
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [hoverChange, setHoverChange] = useState(0);
  const { state: {feedback}, dispatch} = useUser();

  const authentication = getAuth(firebaseApp);

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      message: '',
      rating: 3
    },
    onSubmit: async (values) => {
      try {
        setError('');
        setSubmitted(true);
        if(authentication.currentUser === null) {
          return
        }
        await authService.sendFeedback(
          authentication.currentUser?.uid,
          values.email,
          values.fullName,
          values.message,
          values.rating.toString(),
          "",
        );
        dispatch({
          type: 'set_feedback',
          payload: true
        });
      } catch(err: any) {
        setError(err.code);
      }
    }
  });

  useEffect(() => {
    if(formik.values.rating < 1) {
      formik.setFieldValue('rating', 1);
    }
  }, [formik.values.rating])

  return (
    <Layout>
      <div className='feedback__page'>
        <div className="feedback__flex">
        <div className="feedback__img">
          <img src={girlContact} alt="" className="girlFeedback" />
        </div>
          <div className="form__feedback">
            {
              submitted || feedback ? (
                <div className="feedback__submitted">
                  <h2>Feedback Sent</h2>
                  <img src={checkMark} alt="" className="check_mark"/>
                  <p>Thank you for your feedback. We are striving to improve our product and your experience, so your input is much appreciated.</p>
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="feedback__header">
                    <h2>{error}</h2>
                    <h2>Give us your feedback</h2>
                    <p>Your opinion matters a lot!</p>
                    <span>{error}</span>
                  </div>
                  <div className="feedback__input">
                    <input type="email" name="email" required id="email" placeholder="Enter your email" onChange={formik.handleChange} value={formik.values.email} />
                    <input type="text" name="fullName" required id="fullName" placeholder="Enter your name" onChange={formik.handleChange} value={formik.values.fullName} />
                  </div>
                  <div>
                    <p>How would you rate our product</p>
                    <Rate value={formik.values.rating} allowHalf={hoverChange < 1 ? false : true} onHoverChange={(value) => setHoverChange(value)} onChange={(value) => formik.setFieldValue("rating", value)} />
                  </div>
                  <div className="textarea__input">
                    <p>Is there anything else you would like to share with us?</p>
                    <textarea name="message" id="message" required placeholder="Type your message" onChange={formik.handleChange} value={formik.values.message}></textarea>
                  </div>
                  <button type="submit">Send</button>
                </form>
              )
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Feedback;
