import React from 'react'
import { Link } from 'react-router-dom'

export const GetStarted = () => {
  return (
    <div className="second-section__landing">
    <div className="withoutTruhu"></div>
    <div className="second-section-text__landing">
      <h2>Bring color truth to your screens and projects!</h2>
      <p>
        Enjoy accurate colors in your photography, illustrations, designs, or videos
        <br /> all your hues will be true across all your monitors, at work, at home, or on
        the go!
      </p>
      <Link to="/product">
        <button>Get started</button>
      </Link>
    </div>
    <div className="withTruhu"></div>
  </div>
  )
}
