import React from "react";
import Layout from '../../components/Layout';
import '../../Styles/NotFound.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const NotFound = () => {
  return (
    <Layout>
      <div className='bgColor'>
        <div className='first-section__notFound'>
          <div className='first-section-text__notFound'>
            <h2>Oops! That page can&apos;t be found.</h2>
            <p>It looks like nothing was found at this location</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
