import React from 'react'
// ---------images---------
import blackquoteTop from '../../assets/icons/black_quote.png';
import petapixel from '../../assets/icons/petapixel.svg';
const AboutSection = () => {
  return (
    <div className="big-brother">
          <div className="main-container">
            <a
              href="https://petapixel.com/2023/06/06/truhu-review-a-simple-way-to-calibrate-monitors-with-a-smartphone/"
              target="blank">
              <div className="card-container">
                <div className="test-container">
                  <p className="mt-4">
                    {' '}
                    Never has a company achieved something quite like what TruHu is doing. I got
                    similar results [to colorimeters] in a fraction of the time, at a fraction of
                    the cost, and using just my phone. TruHu is the perfect gateway to a better and
                    more consistent color. For many users, it&apos;s the last calibration{' '}
                    <span style={{ fontWeight: 700 }}>
                      &quot;technology they&apos;ll need.&quot;
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'cadetblue',
                      fontSize: '16px',
                      marginRight: '20px',
                      textAlign: 'end'
                    }}>
                    Read More...
                  </p>
                </div>
                <div className="black_quote_wrapper">
                  <img src={blackquoteTop} alt="" className="quote-black" />
                </div>

                <div className="card-bg">
                  <div className="img-container">
                    <img src={petapixel} />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
  )
}

export default AboutSection