import React, { useEffect, useState } from 'react';
import '../../Styles/Payment.scss';
import Products from './Products';
import { IProduct } from '../../types/product.types';
import PaymentForm from './PaymentForm';
import { useUser } from '../../contexts/userContext';
import { stripeService } from '../../services/stripe.service';
import { authService } from '../../services/auth.service';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import { Link } from 'react-router-dom';
import mainLogo from '../../assets/icons/Logo.svg';
import PaymentLayout from '../../components/Payment/Payment-layout';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { usePlatformDetect } from '../../hooks/usePlatformDetect.hook';

const Payment = () => {
  const auth = getAuth(firebaseApp);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const { state: user, dispatch } = useUser();
  const [dataSubscriptionLoaded, setDataSubscriptionLoaded] = useState(false);
  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const history = useHistory();

  const {
    deviceInfo: { device, platform }
  } = usePlatformDetect();

  console.log('deviceInfo: ', device, platform);




  const checkSubscription = async () => {
    const response = await authService.checkSubscription();

    if (
      response.responseObject.stripeInfo.customerId === null &&
      response.responseObject.activeSubscription === false
    ) {
      const displayName = auth.currentUser?.displayName;
      if (displayName) {
        const [firstName, lastName] = displayName.split(' ');

        const customerResponse = await stripeService.createCustomer(
          auth.currentUser.email!,
          firstName,
          lastName
        );

        dispatch({
          type: 'set_stripe_customer_id',
          payload: customerResponse.responseObject.id
        });
      } else {
        console.error('Display name is: ', displayName);
      }
    }

    if (response.responseObject.activeSubscription === true) {
      dispatch({ type: 'set_subscribed', payload: true });
      dispatch({
        type: 'set_subscription_id',
        payload: response.responseObject.stripeInfo.stripeId
      });
      dispatch({ type: 'set_is_subscribed', payload: true });
    }

    if (response.responseObject.stripeInfo.customerId) {
      dispatch({
        type: 'set_stripe_customer_id',
        payload: response.responseObject.stripeInfo.customerId
      });
    }

    if (response.responseObject.activeSubscription === false) {
      dispatch({
        type: 'set_subscribed',
        payload: false
      });
      dispatch({
        type: 'set_is_subscribed',
        payload: false
      });
    }

    if (response.responseObject.isTrial === true) {
      dispatch({
        type: 'set_trial',
        payload: true
      });
    } else {
      dispatch({
        type: 'set_trial',
        payload: false
      });
    }

    if (response.responseObject.requestedToBeCancelled) {
      setSubscriptionCancelled(true);
    }

    setDataSubscriptionLoaded(true);
  };

  useEffect(() => {
    // Fetch products and prices
    getAllPrices();
    //check if isLoggedIn
    if (user.isLoggedIn) {
      checkSubscription();
    }
  }, []);

  const mapProductDTOToProduct = (productDTO: any): IProduct => ({
    productId: productDTO.productId,
    priceId: productDTO.id,
    unitAmount: productDTO.unitAmount,
    recurring: {
      interval: productDTO.recurring.interval
    },
    description: productDTO.metadata.Description || "Purchase an annual TruHu subscription and calibrate your screen an unlimited number of times."
  });

  const getAllPrices = async () => {
    const response = await stripeService.getAllPrices();
    const prodsArray = response.responseObject.map(
      (product: {
        id: string;
        productId: string;
        unitAmount: number;
        recurring: { interval: string };
        description: string;
      }) => mapProductDTOToProduct(product)
    );
    if (history.location.pathname === '/payment/try') {
      prodsArray.push({
        id: 'Free option',
        productId: 'Free option Id',
        unitAmount: 0,
        recurring: { interval: 'Free trial' },
        description: 'Try a free monitor calibration on us and compare color profiles before purchasing a subscription.'
      });
    }
    setProducts(prodsArray);

    if (products) {
      setSelectedProduct(mapProductDTOToProduct(response.responseObject[0]));

      // if (history.location.pathname === '/payment/buy') {
      //   setSelectedProduct(mapProductDTOToProduct(response.responseObject[1]));
      // } else {
      //   setSelectedProduct(mapProductDTOToProduct(response.responseObject[0]));
      // }
    }
  };

  if (user.subscribed) {
    return <Redirect to="/success" />;
  }

  return (
    <PaymentLayout>
      <div className="payment__list">
        <div className="payment__header">
          <Link to="/">
            <img src={mainLogo} alt="logo" className="logo__header" />
          </Link>
        </div>
        <h2>Start using TruHu</h2>
        {/* <h3>Subscription</h3> */}
        {/* {platform && platform === 'Windows' || device && device === 'Android' ? (
          <>
            <p>Windows / Android coming in 2024</p>
            <Link to={'/wait-list'}>
              <button className="subscribe__button">Join the Wait List</button>
            </Link>
          </>
        ) : ( */}
          <>
            <Products
              products={products}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
            <PaymentForm selectedProduct={selectedProduct} />
          </>
        {/* )} */}
      </div>
    </PaymentLayout>
  );
};

export default Payment;
