import React from 'react';
import Layout from '../../components/Layout';
import '../../Styles/Product.scss';
// import product1 from '../../assets/webp_images/product_1.webp';
// import calibratePhone from '../../assets/icons/callibrate_phone.svg';
import circles_product from '../../assets/icons/circles_product.svg';
import circles2_product from '../../assets/icons/circles2_product.svg';
// import hero3 from '../../assets/images/Hero3.png';
import line from '../../assets/icons/line.svg';
import { Link } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { usePlatformDetect } from '../../hooks/usePlatformDetect.hook';
import { Helmet } from 'react-helmet';

import monitorImage from '../../assets/images/hero-nou.png';

import Testimonials from '../../components/Landing/Testimonials';
import AboutSection from '../../components/Landing/AboutSection';
import HowTruhuWorks from '../../components/Product/HowTruhuWorks';

// import phoneImage from '../../assets/webp_images/hand_phone_hero.webp';

const Product = () => {

  // const history = useHistory();


  const {
    state: { subscribed, isLoggedIn }
  } = useUser();

  const {
    deviceInfo: { device, platform }
  } = usePlatformDetect();

  const redirectAppStore = `https://apps.apple.com/us/app/TRUHu/id${process.env.REACT_APP_APPLE_APP_ID}/`;


  // function handleClick() {
  //   history.push("/payment/buy");
  // }

  return (
    <Layout>
      <div className="bgColor">
        <Helmet>
          <title>TruHu - Monitor Color Calibration App | How It Works</title>
          <meta
            name="description"
            content="​The TruHu monitor calibration app uses your phone’s camera to capture the performance of your screen and create an optimized ICC monitor profile."
          />
        </Helmet>
        <div className="first-section__product">
          <div className="first-section-text__product">
            <h1>Monitor Color Calibration App</h1>
            <h2 className="first-section-second-text">
              See color the way it
              <br /> was intended
            </h2>
            <p>
              TruHu uses a desktop and mobile app to calibrate your monitor with <br /> your
              smartphone&apos;s camera, helping you achieve professional <br /> color accuracy in
              just a few easy steps.
            </p>
            <div className="links__product">
              <img src={circles_product} alt="" className="circles_product" />
              <img src={line} alt="" className="line__product" />
            </div>
            <div className="links__payment">
              {!subscribed && (
                <>
                  <Link to={isLoggedIn ? '/payment/buy' : 'signup'}>
                    <div className="actionLinkContainer">
                      <div>
                        <button className="subscribe__button">Subscribe</button>
                        <p className="label text-center">($39.99/yr)</p>
                      </div>
                    </div>

                  </Link>
                  <Link to={'/download'} className="link__try">
                    <div className="actionLinkContainer">
                      <div>
                        <button className="free-trial__button" >Try it</button>
                        <p className="label text-center">(Free Trial)</p>
                      </div>
                    </div>

                  </Link>

                </>
              )}
              {subscribed && (
                <>
                  {platform === 'Windows' || device === 'Android' ? (
                    <Link to={'/wait-list'}>
                      <button className="subscribe__button">Join the Wait List</button>
                    </Link>
                  ) : (
                    <a href={platform !== 'MacOs' ? redirectAppStore : '/download'}>
                      <button className="subscribe__button">Download</button>
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="gradient__product">
            <img src={circles2_product} alt="" className="circles2__product" />
            {/* <img src={phoneImage} alt="" className="phone" /> */}

            <img src={monitorImage} alt="" className="monitor " />
            {/* <img src={product1} alt="" className="product1" /> */}
          </div>
        </div>
        <div className="second-section__product">
          {/* <img src={calibratePhone} alt="" className="calibratePhone" /> */}
          <div>
            <iframe
              width="800"
              height="480"
              src="https://www.youtube.com/embed/UQPzdybPNVM"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <div className="second-section-text__product">
            <h2>Calibrate in a Few Easy Steps</h2>
            <br />
            <p>
              Make sure you have both TruHu Desktop and Mobile open, as they will walk you through
              the following steps:
            </p>
            <br />
            <ol>
              <li>
                <div>
                  <h4>Adjust ambient lighting</h4>
                  <p>
                    Make sure no light falls on your screen and that the ambient light is similar to
                    your typical work environment.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4>Set brightness</h4>
                  <p>
                    On your desktop, set the brightness of the monitor you want to calibrate to a
                    level appropriate for the ambient light in your work environment.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4> Set color temperature</h4>
                  <p>
                    In the mobile app, TruHu lets you select between multiple color temperature
                    settings
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4>Capture gray</h4>
                  <p>
                    Using both apps, point the camera at the gray target on the monitor until the
                    yellow rectangle and the green square line up. Then take the photo.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4>Capture color</h4>
                  <p>
                    Using both apps, point the camera at the color target on the monitor until the
                    yellow rectangle and the green square line up. Then take the photo.
                  </p>
                </div>
              </li>
              <li className="lastItem">
                <div>
                  <h4>Calibration complete</h4>
                  <p>Your new color profile is ready to use. </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className="third-section__product">
          <h2>
            Supported Devices and
            <br /> Operating Systems
          </h2>
          <ol>
          <li>
              <div>
                <p>iPhone 8 and later</p>
              </div>
            </li>
            <li>
              <div>
                <p>macOS – Mojave / 10.14 and later </p>
              </div>
            </li>
          
            <li>
              <div>
                <p>Microsoft Windows – 10 and 11</p>
              </div>
            </li>
            <li>
              <div>
                <p>Android coming in late 2024</p>
              </div>
            </li>
          </ol>
          <div className="actionLinkContainer">
            <div>
              <a href={isLoggedIn ? '/payment/try' : 'signup'}>
                <button className="subscribe__button">Subscribe</button>
                <p className="label text-center">($39.99/yr)</p>
              </a>
            </div>
            <div>
              <Link to={'/download'} className="link__try">
                <button className="free-trial__button" >Try it</button>
                <p className="label text-center">(Free Trial)</p>
              </Link>
            </div>
          </div>
        </div>
       <HowTruhuWorks/>
        <h2 className="testimonials-header">What TruHu Users Say…</h2>
      <AboutSection/>
        <Testimonials sectionClass="forth-section__product" />
      
        {/* <Newsletter /> */}
      </div>
    </Layout>
  );
};

export default Product;
