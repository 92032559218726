import React, {useState} from "react";
import { authService } from '../../services/auth.service';
import { useFormik } from 'formik';
import Layout from '../../components/Layout';
import circles_contact from '../../assets/icons/circles_contact.svg';
import elipse_contact from '../../assets/icons/elipse_contact.svg';
import checkMark from '../../assets/icons/check-mark.svg';
import girlContact from '../../assets/images/girl_contact_resized.png';
import '../../Styles/Contact.scss';

const Contact = () => {
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      message: ''
    },
    onSubmit: async (values) => {
      try {
        setError('');
        setSubmitted(true);
        await authService.sendMessage(
          values.email,
          values.fullName,
          values.message
        );
      } catch(err: any) {
        setError(err.code);
      }
    }
  });
  return (
    <Layout>
      <div className='contact__page'>
        <img src={circles_contact} alt="" className="circles_contact"/>
        <div className="contact__flex">
          <div className="form__contact">
            <img src={elipse_contact} alt="" className="elipse_contact"/>
            {
              submitted ? (
                <div className="contact__submitted">
                  <h2>Message Sent</h2>
                  <img src={checkMark} alt="" className="check_mark"/>
                  <p>Thank you for your message. We will get in contact with you as soon as possible.</p>
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="contact__header">
                    <h2>Contact us</h2>
                    <p>Your opinion matters a lot!</p>
                    <span>{error}</span>
                  </div>
                  <div className="contact__input">
                    <input type="email" name="email" required id="email" placeholder="Enter your email" onChange={formik.handleChange} value={formik.values.email} />
                    <input type="text" name="fullName" required id="fullName" placeholder="Enter your name" onChange={formik.handleChange} value={formik.values.fullName} />
                  </div>
                  <div className="textarea__input">
                    <p>Feedback</p>
                    <textarea name="message" id="message" required placeholder="Type your message" onChange={formik.handleChange} value={formik.values.message}></textarea>
                  </div>
                  <button type="submit">Send</button>
                </form>
              )
            }
          </div>
        </div>
        <div className="contact__img">
          <img src={girlContact} alt="" className="girlContact"/>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
