import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { Table, Pagination } from 'antd';
import { useFeedback } from '../../contexts/feedbackContext';
import { feedbackService } from '../../services/feedback.service';
import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import circles1_faq from '../../assets/icons/circles1_faq.svg';
import circles2_faq from '../../assets/icons/circles2_faq.svg';
import '../../Styles/Admin.scss';

const Admin: React.FC = () => {
  const {
    state: { isAdmin }
  } = useUser();
  const history = useHistory();

  const {
    state: { size, totalElements, type, data },
    computed: { oneBasedPage, start },
    dispatch
  } = useFeedback();
  const [loading, setLoading] = useState(false);
  const columns = [
    // {
    //   title: 'Date',
    //   dataIndex: '',
    //   key: 'date',
    //   render: (record: string) => record.timestamp.slice(0,10)
    // },
    { title: 'Timestamp', dataIndex: 'timeStamp', key: 'timestamp' },
    { title: 'Mode', dataIndex: 'mode', key: 'mode' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Device', dataIndex: 'device', key: 'device' },
    { title: 'Display', dataIndex: 'display', key: 'display' },
    { title: 'Mac Model', dataIndex: 'macModel', key: 'macModel' },
    { title: 'Backlight', dataIndex: 'backlight', key: 'backLight' },
    { title: 'Temperature', dataIndex: 'temperature', key: 'temperature' },
    { title: 'Message', dataIndex: 'message', key: 'message' }
  ];

  const errorColumns = [
    { title: type === 1 ? 'Error #' : '#', dataIndex: 'error', key: 'error' },
    ...columns
  ];

  const loadFeedbacks = async () => {
    setLoading(true);
    try {
      const {
        responseObject: { totalResults, data }
      } = await feedbackService.fetchFeedbacks(type, start, size);
      dispatch({ type: 'set_total_elements', payload: totalResults });
      dispatch({ type: 'set_data', payload: data });
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  const getPage = async (oneBased: number) => {
    dispatch({ type: 'set_page', payload: oneBased - 1 });
  };

  useEffect(() => {
    loadFeedbacks();
  }, [oneBasedPage, type]);

  if (isAdmin === null) {
    return null;
  }

  if (isAdmin === false) {
    history.push('/');
    return null;
  }

  return (
    <Layout>
      <div className="admin__page">
        <img src={circles1_faq} alt="" className="circles1_faq" />
        <img src={circles2_faq} alt="" className="circles2_faq" />
        <div className="admin__questions">
          <div className="admin__section horizontal-flex">
            <h4>Show:</h4>
            <div className="report-filter-root">
              <select
                className="report-filter"
                onChange={(e) => {
                  const payload = parseInt(e.target.value, 10);
                  dispatch({ type: 'set_type_and_reset_page', payload });
                }}>
                <option value={3}>Feedback</option>
                <option value={2}>Errors</option>
              </select>
            </div>
          </div>
          <div className="admin__section">
            <Table
              columns={type === 3 ? columns : errorColumns}
              dataSource={data}
              loading={loading}
              pagination={false}
            />
            <Pagination
              className="admin__pagination horizontal-flex"
              current={oneBasedPage}
              total={totalElements}
              pageSize={size}
              showSizeChanger={false}
              onChange={getPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Admin;
