import React from 'react';
import { IProduct } from '../../types/product.types';
import { useHistory } from 'react-router-dom';

interface IProductProps {
  product: IProduct;
  selected: boolean;
  setSelected: (product: IProduct) => void;
  numberOfProducts: number;
}
const Product: React.FC<IProductProps> = ({ product, selected, setSelected, numberOfProducts }) => {
  const history = useHistory();

  const handleChange = () => {
    setSelected(product);
  };

  return (
    <>
      <div className="product__style">
        <div className="product__input">
          <div className={history.location.pathname === '/payment/buy' ? 'subscription-title' : ''}>
            <input
              onChange={handleChange}
              type={history.location.pathname === '/payment/buy' ? 'hidden' : 'radio'}
              value={product.productId}
              name="subscription"
            />
            <p className="text-bold">
              {product.recurring.interval === 'year'
                ? 'Annual Subscription'
                : product.recurring.interval}
            </p>
          </div>
          <p className="text-bold">${product.unitAmount / 100}</p>
        </div>
        <div className="product__description">
          <p>{product.description}</p>
        </div>
      </div>
    </>
  );
};

export default Product;
