import React, { useEffect, useState } from 'react';
import mainLogo from '../../assets/icons/Logo.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { getAuth, signOut, updateProfile } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import MobileHeader from './MobileHeader';
import card from '../../assets/icons/card.svg';
import close from '../../assets/icons/close.svg';
import userIcon from '../../assets/icons/78-user.svg';
import { authService } from '../../services/auth.service';
import '../../Styles/Header.scss';

const Header = () => {
  const location = useLocation();
  const {
    state: { isLoggedIn, subscribed, isAdmin },
    dispatch
  } = useUser();
  const authentication = getAuth(firebaseApp);

  const [initials, setInitials] = useState('');


  useEffect(() => {
    if (authentication.currentUser) {
      const fullName = (authentication?.currentUser?.displayName ?? 'First Last').split(' ');

      const initialsName = fullName[0].charAt(0) + (fullName[1] ?? '').charAt(0);
      setInitials(initialsName);
    }
  }, [authService.editUsername, updateProfile, authentication.currentUser?.displayName]);

  const signOutUser = () => {
    signOut(authentication)
      .then(() => {
        dispatch({ type: 'logout' });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 1020;

  useEffect(() => {
    
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);


  


  return width < breakpoint ? (
    <MobileHeader />
  ) : (
    <div className={`header sticky`}>
      <div className="links__header">
        <Link to="/">
          <img src={mainLogo} alt="logo" className="logo__header" />
        </Link>
        <Link to="/product" className={location.pathname === '/product' ? 'colored-link' : ''}>
          Product
        </Link>
        <Link to="/download" className={location.pathname === '/download' ? 'colored-link' : ''}>
          Download
        </Link>
        <a href={process.env.REACT_APP_BLOG_URL}>Blog</a>
        <a href={process.env.REACT_APP_PODCAST_URL}>Podcast</a>
        <Link to="/about" className={location.pathname === '/about' ? 'colored-link' : ''}>
          About us
        </Link>
        {isLoggedIn && isAdmin && (
          <>
            <Link
              to="/reports-admin"
              className={location.pathname === '/reports-admin' ? 'colored-link' : ''}>
              Admin
            </Link>
            <Link
              to="/invites-admin"
              className={location.pathname === '/invites-admin' ? 'colored-link' : ''}>
              Invite
            </Link>
          </>
        )}
      </div>
      <div className="sign">
        {isLoggedIn ? (
          <div className="flex justify-center">
            <div>
              <div className="dropdown relative">
                <button
                  className="
                      dropdown-toggle
                      header-circle
                    "
                  type="button"
                  id="dropdownMenuButton1d"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {authentication.currentUser ? initials : ''}
                </button>
                <ul
                  className="
                      dropdown-menu
                      min-w-max
                      absolute
                      hidden
                      bg-white
                      text-base
                      z-50
                      float-left
                      py-2
                      list-none
                      text-left
                      rounded-lg
                      shadow-lg
                      mt-1
                      hidden
                      m-0
                      bg-clip-padding
                      border-none
                    "
                  aria-labelledby="dropdownMenuButton1d">
                  <li>
                    <div
                      className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                        ">
                      <p className="logged_name">{authentication.currentUser?.displayName}</p>
                      <p className="logged_email logged_links">
                        {authentication.currentUser?.email}
                      </p>
                    </div>
                  </li>
                  <hr className="h-0 my-2 border border-solid border-t-0 border-gray-700 opacity-25" />
                  <li>
                    <Link
                      className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          logged_links
                        "
                      to="/account">
                      <img src={userIcon} alt="" /> My account
                    </Link>
                  </li>
                  {subscribed ? (
                    <li>
                      <Link
                        className="
                              dropdown-item
                              py-2
                              px-4
                              block
                              w-full
                              whitespace-nowrap
                              bg-transparent
                              logged_links
                            "
                        to="/subscription">
                        <img src={card} alt="" /> Subscription and card
                      </Link>
                    </li>
                  ) : null}
                  <li>
                    <a
                      className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          logged_links
                          logged_links-sign-out
                        "
                      href="/"
                      onClick={signOutUser}>
                      <img src={close} alt="" /> Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Link to="/signin">Sign in</Link>
            {/* <Link to="/signup">
              <button className="sign-up-btn">Sign up</button>
            </Link> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
