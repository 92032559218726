import React from 'react'

const HowTruhuWorks = () => {
  return (
    <div className="technical-explainer">
    <h2>How TruHu Works Its Magic</h2>
    <p className="technical-explainer-paragraph">
      Our monitor color calibration tool uses two apps in tandem - one desktop and one mobile
      - to analyze color data from your screen, determine its backlight technology, and create
      an optimized color profile, all done in one minute.
    </p>
    <div className="details">
      <div>
        <h3>Colorimetric Accuracy</h3>
        <p>
          Using image sensor data from your phone’s camera, TruHu bypasses your device’s
          standard image processing to send RAW sensor measurements through our proprietary
          algorithms, converting them to colorimetric data. The results are very similar in
          quality to those generated by colorimeter-based monitor calibration products
          (CBMCPs).
        </p>
      </div>
      <div>
        <h3>Luminance</h3>
        <p>
          The default luminance on most CBMCPs is 120 cd/m2 (or NITS). This is not bright
          enough for many working environments, which can have significant overhead or natural
          light. TruHu trusts you to know the appropriate brightness level for your working
          environment, rather than forcing you into an arbitrary luminance number.
        </p>
      </div>
      <div>
        <h3>Metamerism</h3>
        <p>
          Colorimeters ”see” colors differently based on the backlight technology of your
          monitor. Your phone’s camera has the same limitation. With TruHu, you don’t have to
          know all those technical details - we use an extensive database of displays and
          their respective backlight technologies to automatically determine the best way to
          calibrate your screen and thus minimize metamerism.
        </p>
      </div>
    </div>
  </div>
  )
}

export default HowTruhuWorks