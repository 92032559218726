import axios, { AxiosInstance } from 'axios';
import { ServerRequest } from '../types/request.types';
import downloadFile from "../helpers/downloadFile";

export default class DownloadService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async downloadBeta(version: string, buildType: string): Promise<ServerRequest<any>> {
    const response = await this.httpClient({url: version, baseURL: `https://truhu-mobile-builds.s3.us-west-2.amazonaws.com${buildType === "mac" ? '': '/truhu-desktop-win'}`, responseType: 'blob'});
    downloadFile(response.data,`${version}`, 'application/octet-stream')
    return response.data;
  }
}

export const downloadService = new DownloadService(axios);
