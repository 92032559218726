import React from "react";
import Footer from '../Footer/Footer';
import signCircles from '../../assets/icons/SignCircles.svg';
import '../../Styles/Payment.scss';

const PaymentLayout = (props: any) => {
  return (
    <div className='payment__page'>
      <img src={signCircles} alt="" className="payment__circles"/>
      <div className="payment__flex">
        <div className="payment__form">
          {props.children}
        </div>
        <div className="payment__img">
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentLayout;