import React, { useState, useEffect } from 'react';
import { authService } from '../../services/auth.service';
import { useFormik } from 'formik';
import { getAuth, updateProfile } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import UserInfo from '../../components/UserInfo/UserInfo';
import ChangePassword from '../AuthAction/ChangePassword';
import toast, { Toaster } from 'react-hot-toast';
import '../../Styles/Contact.scss';

const PersonalInformation = () => {
  const authentication = getAuth(firebaseApp);
  const [initials, setInitials] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [error, setError] = useState('');
  const [beError, setBeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authentication.currentUser) {
      const fullName = (authentication?.currentUser?.displayName ?? 'First Last').split(' ');
      const initialsName = fullName[0].charAt(0) + (fullName[1] ?? '').charAt(0);
      setInitials(initialsName);
    }
  }, [authService.editUsername, updateProfile, authentication.currentUser?.displayName]);

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: async (values) => {
      const splitOnSpace = values.name.split(' ');
      const firstName = splitOnSpace[0];
      const lastName = splitOnSpace[splitOnSpace.length - 1];

      setIsLoading(true);

      if (values.name && values.name.includes(' ')) {
        try {
          setError('');
          setBeError('');
          if (authentication.currentUser === null) {
            return;
          }
          await authService.editUsername(authentication.currentUser?.uid, firstName, lastName);
          await updateProfile(authentication.currentUser, {
            displayName: `${firstName} ${lastName}`
          });

          setIsLoading(false);
          toast.success('Name successfully updated!');
        } catch (err: any) {
          setBeError(err.code);
        }
      } else {
        setError('Please enter a valid First and Last name');
        setIsLoading(false);
      }
    }
  });

  if (changePassword) {
    return <ChangePassword setChangePassword={setChangePassword} />;
  }

  return (
    <UserInfo>
      <div className="personal__information">
        <div className="user_info">
          <button className="circle">{initials}</button>
          <div className="word-break-info">
            <h2>{authentication.currentUser?.displayName}</h2>
            <p>{authentication.currentUser?.email}</p>
          </div>
        </div>
        <form className="personal__information-form" onSubmit={formik.handleSubmit}>
          <label>Full Name</label>
          {error ? (
            <div>
              <input
                type="text"
                id="name"
                className="error-login"
                onChange={formik.handleChange}
                name="name"
                placeholder={authentication.currentUser?.displayName || ''}
              />
              <span className="span__error-login">{error}</span>
            </div>
          ) : (
            <input
              type="text"
              id="name"
              onChange={formik.handleChange}
              name="name"
              placeholder={authentication.currentUser?.displayName || ''}
            />
          )}
          <div className="personal__information-buttons">
            <p onClick={() => setChangePassword(true)} className="cursor-pointer">
              <span>Change password</span>
            </p>
            <button type="submit">
              {isLoading ? <p className="text-center">Loading...</p> : 'Save'}
            </button>
          </div>
          <div>
            <Toaster />
          </div>
        </form>
      </div>
    </UserInfo>
  );
};

export default PersonalInformation;
