import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IProduct } from '../../types/product.types';
import { useUser } from '../../contexts/userContext';
import { firebaseApp } from '../../firebase-config';
import { getAuth } from 'firebase/auth';
import { stripeService } from '../../services/stripe.service';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

interface IPaymentFormProps {
  selectedProduct: IProduct | undefined;
}

const PaymentForm: React.FC<IPaymentFormProps> = ({ selectedProduct }) => {
  const auth = getAuth(firebaseApp);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const {
    state: { customerStripeId, isLoggedIn },
    dispatch
  } = useUser();

  const setSubscription = (subscriptionId: string) => {
    dispatch({ type: 'set_subscribed', payload: true });
    dispatch({ type: 'set_subscription_id', payload: subscriptionId });
    dispatch({ type: 'set_is_subscribed', payload: true });
    localStorage.setItem('lastInvoiceId', '');
  };

  const setCardError = (error: string) => {
    if (elements && elements.getElement(CardElement)) {
      elements!.getElement(CardElement)!.clear();
    }
    setTimeout(() => {
      setError(error);
    }, 200);
    // setLoading(false);
  };

  const handleSubmit = async (event: any) => {

    event.preventDefault();

    // Verify that everything exists and has loaded
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement || !auth.currentUser) {
      return;
    }

    setLoading(true);

    try {
      const stripeResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: auth.currentUser.displayName || '',
          email: auth.currentUser.email || ''
        }
      });

      const retryInvoiceId = localStorage.getItem('lastInvoiceId');
      if (retryInvoiceId && stripeResult.paymentMethod) {
        const serverResponse = await stripeService.retryInvoice(
          customerStripeId,
          stripeResult.paymentMethod.id,
          retryInvoiceId
        );

        handlePaymentThatRequiresCustomerAction(
          serverResponse.responseObject.paymentIntent,
          stripeResult.paymentMethod.id,
          serverResponse.responseObject.subscriptionId,
          true,
          serverResponse.responseObject.paymentIntent.invoice
        );
        return;
      }

      if (stripeResult.paymentMethod && selectedProduct) {
        // Start the subscription flow on BE
        createSubscription(
          customerStripeId,
          stripeResult.paymentMethod.id,
          selectedProduct.priceId
        );
      }
      const isErrorMSg = stripeResult?.error?.message
      if(isErrorMSg){        toast.error(stripeResult?.error?.message as string);
        setLoading(false);
      }
      // setTimeout(() => {
      //   // setLoading(false);
      //   history.push("/")
      // }, 1000);


    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  };

  const handlePaymentThatRequiresCustomerAction = async (
    paymentIntent: any,
    paymentMethodId: string,
    subscriptionId: string,
    isRetry = false,
    invoice?: any
  ) => {
    // Verify that everything needed exists
    const usedPaymentIntent = invoice ? invoice : paymentIntent;
    if (
      (stripe && usedPaymentIntent.clientSecret) ||
      (stripe && isRetry && usedPaymentIntent.status === 'requires_payment_method')
    ) {
      // This initiates the 3D Secure flow
      const stripeResult = await stripe.confirmCardPayment(usedPaymentIntent.clientSecret, {
        payment_method: paymentMethodId
      });

      // If the payment succeeded, confirm it in FE
      if (stripeResult.paymentIntent?.status === 'succeeded') {
        setSubscription(subscriptionId);
        toast.success('Subscription Success!');
        // history.push("/")
      } else if (stripeResult.error?.message) {
        setCardError(stripeResult.error.message);
      }
    }
  };

  const createSubscription = async (
    customerId: string,
    paymentMethodId: string,
    priceId: string
  ) => {
    try {
      const serverResult = await stripeService.createSubscription(
        customerId,
        paymentMethodId,
        priceId
      );
      // If the payment was successful, confirm the payment on FE
      if (serverResult.responseObject && serverResult.responseObject.status === 'active') {
        setSubscription(serverResult.responseObject.id);

      } else {
        // If the payment requires user action, initiate 3D Secure flow
        if (serverResult.responseObject.latestInvoice.paymentIntent.status === 'requires_action') {
          handlePaymentThatRequiresCustomerAction(
            serverResult.responseObject.latestInvoice.paymentIntent,
            paymentMethodId,
            serverResult.responseObject.id
          );
        } else if (
          serverResult.responseObject.latestInvoice.paymentIntent.status ===
          'requires_payment_method'
        ) {
          localStorage.setItem('lastInvoiceId', serverResult.responseObject.latestInvoice.id);
          setCardError('Your card was declined! Please try another card.');
        }
      }
    } catch (err: any) {
      if (err) {
        setCardError('Something went wrong. Please try again later.');
      }

      if (
        err.response.data.Errors[0].CompleteMessage === 'Your card has expired.' ||
        err.response.data.Errors[0].CompleteMessage === 'Your card has insufficient funds.'
      ) {
        setCardError(
          err.response.data.Errors[0].CompleteMessage +
          ' Please try another card or contact your bank.'
        );
      } else if (
        err.response.data.Errors[0].CompleteMessage ===
        'An error occurred while processing your card. Try again in a little bit.'
      ) {
        setCardError(err.response.data.Errors[0].CompleteMessage);
      } else {
        setCardError(
          err.response.data.Errors[0].CompleteMessage + ' Please try again or contact your bank.'
        );
      }
    }finally{
      setLoading(false)
    }
  };

  const onCardChange = (event: any) => {
    setError(event.error ? event.error.message : '');
  };

  return (
    <form onSubmit={handleSubmit} className="subscribe__form"  >
      {selectedProduct?.unitAmount ?? 0 > 0 ? (
        <div className="card__input-stripe">
          <h3>Card Number</h3>
          <CardElement
            options={{ disabled: isLoggedIn ? false : true }}
            onChange={onCardChange}
            className="p-3 rounded-lg bg-white form__card"
          />
          {!isLoggedIn ? (
            <span className="span__error-stripe">
              Please{' '}
              <Link to="/signin" className="text-underline">
                {' '}
                login{' '}
              </Link>{' '}
              in order to purchase a subscription
            </span>
          ) : null}
        </div>
      ) : null}
      {error && <p className="text-red-500 text-sm card-error">{error}</p>}
      <div className="payment__buttons">
        {selectedProduct?.unitAmount ?? 0 > 0 ? (
          <button disabled={!isLoggedIn || loading} className="payment__button">
            {loading ? <p className="text-center">Loading...</p> : 'Subscribe'}
          </button>
        ) : (
          <Link to="/download">
            <button className="payment__button">Try now</button>
          </Link>
        )}
        <Link to="/product">
          <p className="payment__button-cancel">Cancel</p>
        </Link>
      </div>
      <Toaster />
    </form>
  );
};

export default PaymentForm;
