import React, { ChangeEvent, useEffect, useState } from 'react';
import { stripeService } from '../../services/stripe.service';
import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import '../../Styles/Invites.scss';

type productsType = {
  id: string;
  name: string;
  price: string | number;
};

const DemoSubscriptions = () => {
  const [error, setError] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [products, setProducts] = useState<productsType[] | null>();
  const [productId, setProductId] = useState<string>('');

  const {
    state: { isAdmin }
  } = useUser();
  const history = useHistory();

  const loadProducts = async () => {
    setIsLoading(true);
    try {
      const data = await stripeService.getFilteredPrices();
      setProducts(data.responseObject);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setProductId(ev.target.value);
  };

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = ev.target.files && ev.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file && productId) {
      setIsLoading(true);
      try {
        await stripeService.createDemoSubscription(productId, file);
        setSubmitted(true);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      loadProducts();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  if (isAdmin === null) {
    return null;
  }

  if (isAdmin === false) {
    history.push('/');
    return null;
  }

  return (
    <Layout>
      <div className="invites__page">
        <div className="admin__questions">
          <select
            onChange={handleProductChange}
            defaultValue={'Select Product'}
            className="form_select">
            <option disabled>Select Product</option>
            {products &&
              products.length &&
              products.map((product) => {
                return (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                );
              })}
          </select>
          <input type="file" className="add_file" onChange={handleFileChange} />
          <button onClick={handleUpload} className="upload_btn">
            Upload
          </button>
        </div>
        <div className="admin__section">
          {submitted && !isLoading && <h4 className="success_msg">Successfully submitted!</h4>}
          {error && <h5 className="error_msg">An error occured...&nbsp;{error}</h5>}
        </div>
      </div>
    </Layout>
  );
};

export default DemoSubscriptions;
