import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../../Styles/AlternativePayment.scss';



const AlternativePaymentLayout = (props: any) => {

  return (
    // <div className='alt__payment__page'>
    //   <div className="alt__payment__top__title__container">
    //     <h1 className="alt__payment__page__title">Download TruHu</h1>
    //   </div>
    //   <div className="alt__payment__top__description">
    //     <p>TruHu uses a Desktop and Mobile app together to measure your display’s colors and proceed with calibration  <Link to="/product" className={'colored-link'}>
    //     Learn more
    //     </Link>. </p>
    //     <p>To continue, please download, open, and sign in to both apps:</p>
    //   </div>
      <div className="alt__payment__flex">

        {props.children}
      </div>
 
    // </div>
  );
}

export default AlternativePaymentLayout;

