import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDh3XPeX-NUQimGCvlf4cGTOn6mBs0-YEI',
  authDomain: 'truhu-pro.firebaseapp.com',
  databaseURL: 'https://truhu-pro-default-rtdb.firebaseio.com',
  projectId: 'truhu-pro',
  storageBucket: 'truhu-pro.appspot.com',
  messagingSenderId: '373282338675',
  appId: '1:373282338675:web:c4a4c778fb2fd3c0a3f706',
  measurementId: 'G-7E1WBWPZ3B'
};

export const firebaseApp = initializeApp(firebaseConfig);
