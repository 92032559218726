import React from "react";
import {Redirect as Re, Route as Ro} from "react-router-dom";
import { useUser } from '../contexts/userContext';

const Route = Ro as any;
const Redirect = Re as any;

const PrivateRoute = (props: any) => {
  const { children, ...rest } = props;
  const {state: { isLoggedIn } } = useUser()

  return (
    <Route
      {...rest}
      render={() => {
        return isLoggedIn
          ? (children)
          : (<Redirect to={`/signin${rest.redirect ? `?returnUrl=${rest.redirect}` : ''}`} />)
      }}
    />
  );
}

export {PrivateRoute}
