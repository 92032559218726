import React, { useState } from 'react';
import { BarLoader, CircleLoader, RotateLoader } from 'react-spinners';
import { Link, useHistory } from 'react-router-dom';
import { downloadService } from '../../services/download.service';
import { useUser } from '../../contexts/userContext';
import { useFormik } from 'formik';
import { authService } from '../../services/auth.service';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import PaymentLayout from '../../components/Payment/Payment-layout';
import checkMark from '../../assets/icons/check-mark.svg';
import mainLogo from '../../assets/icons/Logo.svg';
import '../../Styles/WaitList.scss';

const WaitList = () => {
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const authentication = getAuth(firebaseApp);

  const formik = useFormik({
    initialValues: {
      email: '',
      preferredOs: '',
      preferredMobile: ''
    },
    onSubmit: async (values) => {
      try {
        setError('');
        setSubmitted(true);
        await authService.addToWaitList(values.email, values.preferredOs, values.preferredMobile);
      } catch (err: any) {
        setError(err.code);
      }
    }
  });

  return (
    <PaymentLayout>
      <div className="payment__list">
        <div className="payment__header">
          <Link to="/">
            <img src={mainLogo} alt="logo" className="logo__header" />
          </Link>
        </div>
        {submitted ? (
          <div className="contact__submitted">
            <h2>Email Address Added!</h2>
            <img src={checkMark} alt="" className="check_mark" />
            <p>Thank you for your interest!</p>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h3>Android Launching Soon</h3>
            <div className="contact__input">
              <p className="contact__header">
              Enter your email here to be notified when TruHu for Android launches in 2024
              </p>
              <input
                type="email"
                name="email"
                required
                id="email"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <label htmlFor="preferredOs">Preferred Operating System:</label>
              <select
                onChange={formik.handleChange}
                value={formik.values.preferredOs}
                name="preferredOs"
                id="preferredOs"
                required
                className="form_select">
                <option value="" disabled>
                  Select an option
                </option>
                <option value="windows">Windows</option>
                <option value="macOs">MacOs</option>
              </select>

              {/* <label htmlFor="preferredMobile">Preferred Mobile Platform:</label>
              <select
                onChange={formik.handleChange}
                value={formik.values.preferredMobile}
                name="preferredMobile"
                id="preferredMobile"
                required
                className="form_select">
                <option value="" disabled>
                  Select an option
                </option>
                <option value="android">Android</option>
                <option value="ios">iOS</option>
              </select> */}
              <input type="hidden" id="preferredMobile" name="preferredMobile" value="android" />
              <button type="submit">Get Notified</button>
            </div>
          </form>
        )}
      </div>
    </PaymentLayout>
  );
};

export default WaitList;
