import React from 'react'
import { Carousel } from 'react-responsive-carousel';

import personOne from '../../assets/images/Mark_Rutherford_Square.jpg';
import personTwo from '../../assets/images/Stefan_Yazzie_Herbert_Square.jpg';
import personThree from '../../assets/images/Ryan_Graff_Square.jpg';
import personFour from '../../assets/images/Headshot_Jim.jpg';
import testimonials from '../../assets/webp_images/testimonials_photo.webp';
import quoteTop from '../../assets/icons/quote_top.svg';
import quoteBottom from '../../assets/icons/quote_bottom.svg';

const Testimonials = ({sectionClass}:any) => {
  return (
    <div className={sectionClass}>
    <img src={testimonials} alt="" className="testimonialsImg" />
    <div className="testimonials">
      <div className="testimonialsContent">
        <img src={quoteTop} alt="" className="quote-top" />
        <Carousel showThumbs={false} infiniteLoop interval={12000} autoPlay={true}>
          {/* <div style={{ textAlign: 'center', margin: 'auto' }}>
            <div>
              <p>
                I got similar results [to colorimeters] in a fraction of the time, at a
                fraction of the cost, and using just my phone. TruHu is the perfect gateway to
                a better and more consistent color. For many users, it’s the last calibration
                technology they’ll need
              </p>
              <div className="author">
                <img src={petapixel} alt="petapixel" />
                <p>
                  <a
                    href="https://petapixel.com/2023/06/06/truhu-review-a-simple-way-to-calibrate-monitors-with-a-smartphone/"
                    target="blank">
                    PetaPixel - TruHu Review
                  </a>
                </p>
              </div>
            </div>
          </div> */}

          <div style={{ textAlign: 'center', margin: 'auto' }}>
            <div>
              <img
                src={personOne}
                alt=""
                style={{ height: '150px', width: '150px', borderRadius: '8px' }}
              />
              <p>
                ‘I’ve employed a number of high-end color profiling solutions for a couple
                decades and have been a beta tester for X-Rite and Sony. The results I
                achieved with TruHu are comparable to the best of them. TruHu is by far the
                easiest system to use.’
              </p>
              <div className="author">
                <p className="author-name">
                  <a href="https://www.linkedin.com/in/mark-rutherford-647b7/" target="blank">
                    Mark Rutherford
                  </a>
                </p>
                <p>Photographer</p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', margin: 'auto' }}>
            <div>
              <img
                src={personTwo}
                alt=""
                style={{ height: '150px', width: '150px', borderRadius: '8px' }}
              />
              <p>
                ‘I always need an extra screen to work, so I started using TruHu to quickly
                calibrate when I’m at a coworking space or visiting a new office. It’s great
                having accurate color when I’m on the road.’
              </p>
              <div className="author">
                <p className="author-name">
                  <a
                    href="https://www.linkedin.com/in/stefan-yazzie-herbert-606361149/"
                    target="blank">
                    Stefan Yazzie Herbert
                  </a>
                </p>
                <p>Filmmaker</p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', margin: 'auto' }}>
            <img
              src={personThree}
              alt=""
              style={{ height: '150px', width: '150px', borderRadius: '8px' }}
            />
            <div>
              <p>
                ‘I work with artists, who are naturally sticklers for the most accurate color
                in reproductions. TruHu is an excellent addition to my arsenal that helps me
                to guarantee the exacting color my artist collaborators require.’
              </p>
              <div className="author" style={{textAlign: 'left'}}>
                <p className="author-name"><a href="https://www.ryangraff.art" target="blank">Ryan Graff</a></p>
                <p>
                  Fine Art Printer and Publisher
                </p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', margin: 'auto' }}>
            <img
              src={personFour}
              alt=""
              style={{ height: '150px', width: '150px', borderRadius: '8px' }}
            />
            <div>
              <p>
                ‘I already have a colorimeter, but when I need a quick calibration I’m not gonna use that, I just take out my phone and use TruHu. <br></br>I’m a seasoned professional and I know when color looks good and with TruHu it looks good.’
              </p>
              <div className="author">
                <p className="author-name"><a href="https://www.linkedin.com/in/jimraffel/" target="blank">Jim Raffel</a></p>
                <p>
                Color Management Consultant
                </p>
              </div>
            </div>
          </div>
          <div style={{ margin: 'margin-left: 0px; margin-right: 0px;', textAlign: 'left' }}>
            <div>
              <p>
                Just want to give you a big thanks for TruHu. I have an NEC 27 monitor which is several years years old but still performs well and is easily calibrated with SpectraView ll. Alas, the colorimeter and software only work with NEC/Sharp monitors which has left my second monitor - an ancient Apple Cinema woefully inaccurate. I was thinking of replacing it since its inaccuracy was becoming quite annoying when working in Lightroom & Photoshop. I explored purchasing a new colorimeter but that costs far more than the Apple display is worth. A new monitor would have been an expensive solution. But before spending big money on a new display, I decided to give TruHu a try. Eureka moment! The Apple Cinema is now quite serviceable as a second monitor and while it does not completely match the trusty NEC, it works very well for my purposes. 
              </p>
              <div className="author">
                <p className="author-name">
                Christopher Sanderson - <span>Photographer</span>
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </Carousel>
        <img src={quoteBottom} alt="" className="quote-bottom" />
      </div>
    </div>
  </div>
  )
}

export default Testimonials