import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { getAuth, signOut, updateProfile } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import { authService } from '../../services/auth.service';
import arrow from '../../assets/icons/arrow.svg';
import userIcon from '../../assets/icons/user-icon.svg';
import card from '../../assets/icons/card.svg';
import close from '../../assets/icons/close.svg';
import user from '../../assets/icons/78-user.svg';
import Navbar from 'react-bootstrap/Navbar';
import mainLogo from '../../assets/icons/Logo.svg';
import '../../Styles/Header.scss';

export default function MobileHeader() {
  const location = useLocation();
  const {
    state: { isLoggedIn, subscribed },
    dispatch
  } = useUser();
  const authentication = getAuth(firebaseApp);

  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (authentication.currentUser) {
      const fullName = (authentication?.currentUser?.displayName ?? 'First Last').split(' ');

      const initialsName = fullName[0].charAt(0) + (fullName[1] ?? '').charAt(0);
      setInitials(initialsName);
    }
  }, [authService.editUsername, updateProfile, authentication.currentUser?.displayName]);

  const signOutUser = () => {
    signOut(authentication)
      .then(() => {
        dispatch({ type: 'logout' });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
      <nav className="relative flex items-center justify-between px-2 py-3 bg-gray-100">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <Navbar.Collapse in={navbarOpen} className="align-items-center w-full">
            <div
              className={'flex-grow items-center m-1 mb-5 p-3' + (navbarOpen ? ' flex' : ' hidden')}
              id="example-navbar-danger">
              <ul className="flex flex-col list-none items-center justify-center w-full">
                <li className="nav-item text-lg">
                  <Link to="/product">Product</Link>
                </li>
                <li className="nav-item text-lg">
                  <Link to="/download">Download</Link>
                </li>
                <li className="nav-item text-lg">
                  <a href={process.env.REACT_APP_BLOG_URL}>Blog</a>
                </li>
                <li className="nav-item text-lg">
                  <a href={process.env.REACT_APP_PODCAST_URL}>Podcast</a>
                </li>
                <li className="nav-item text-lg">
                  <Link to="/about">About us</Link>
                </li>
               
              </ul>
            </div>
          </Navbar.Collapse>
          <div className="w-screen relative flex justify-between align-items-center">
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
              aria-controls="example-navbar-danger"
              aria-expanded={navbarOpen}>
              {navbarOpen ? (
                <img src={arrow} alt="" />
              ) : (
                <div className="space-y-2">
                  <div className="w-6 h-0.5 bg-gray-600"></div>
                  <div className="w-6 h-0.5 bg-gray-600"></div>
                  <div className="w-6 h-0.5 bg-gray-600"></div>
                </div>
              )}
            </button>
            <Link to="/" className="logo_header-link">
              <img src={mainLogo} alt="" />
            </Link>
            <div>
              {isLoggedIn ? (
                <div className="flex justify-center">
                  <div>
                    <div className="dropdown relative">
                      <button
                        className="
                      dropdown-toggle
                      mobile-circle
                    "
                        type="button"
                        id="dropdownMenuButton1d"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {authentication.currentUser ? initials : ''}
                      </button>
                      <ul
                        className="
                      dropdown-menu
                      min-w-max
                      absolute
                      hidden
                      bg-white
                      text-base
                      z-50
                      float-left
                      py-2
                      list-none
                      text-left
                      rounded-lg
                      shadow-lg
                      mt-1
                      hidden
                      m-0
                      bg-clip-padding
                      border-none
                    "
                        aria-labelledby="dropdownMenuButton1d">
                        <li>
                          <div
                            className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                        ">
                            <p className="logged_name">{authentication.currentUser?.displayName}</p>
                            <p className="logged_email logged_links">
                              {authentication.currentUser?.email}
                            </p>
                          </div>
                        </li>
                        <hr className="h-0 my-2 border border-solid border-t-0 border-gray-700 opacity-25" />
                        <li>
                          <Link
                            className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          logged_links
                        "
                            to="/account">
                            <img src={user} alt="" /> My account
                          </Link>
                        </li>
                        {subscribed ? (
                          <li>
                            <Link
                              className="
                              dropdown-item
                              py-2
                              px-4
                              block
                              w-full
                              whitespace-nowrap
                              bg-transparent
                              logged_links
                            "
                              to="/subscription">
                              <img src={card} alt="" /> Subscription and card
                            </Link>
                          </li>
                        ) : null}
                        <hr className="h-0 my-2 border border-solid border-t-0 border-gray-700 opacity-25" />
                        <li>
                          <a
                            className="
                          dropdown-item
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          logged_links
                          logged_links-sign-out
                        "
                            href="/"
                            onClick={signOutUser}>
                            <img src={close} alt="" /> Sign out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-center">
                    <div>
                      <div className="dropdown relative">
                        <button
                          className="
                      dropdown-toggle
                      px-3
                      py-3
                      transition
                      duration-150
                      ease-in-out
                      flex
                      items-center
                    "
                          type="button"
                          id="dropdownMenuButton1d"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <img src={userIcon} alt="" />
                        </button>
                        <ul
                          className="
                      dropdown-menu
                      min-w-max
                      absolute
                      hidden
                      bg-white
                      text-base
                      z-50
                      float-left
                      py-2
                      list-none
                      text-left
                      rounded-lg
                      shadow-lg
                      mt-1
                      hidden
                      m-0
                      bg-clip-padding
                      border-none
                    "
                          aria-labelledby="dropdownMenuButton1d">
                          <li>
                            <Link
                              className="
                          dropdown-item
                          text-sm
                          py-2
                          px-4
                          font-normal
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          text-gray-700
                          hover:bg-gray-100
                        "
                              to="/signin">
                              Sign in
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              className="
                          dropdown-item
                          text-sm
                          py-2
                          px-4
                          font-normal
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          text-gray-700
                          hover:bg-gray-100
                        "
                              to="/signup">
                              Sign up
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
