import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import { authService } from '../../services/auth.service';
import { Link } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Sign from '../../components/Sign/Sign';

const RegisterForm: React.FC = () => {
  const authentication = getAuth(firebaseApp);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [timeoutIdx, setTimeoutIdx] = useState<any>();

  const {
    state: { isLoggedIn }
  } = useUser();

  useEffect(() => {
    if (!isLoading) {
      history.push('/payment/try');
    }
    return () => {
      clearTimeout(timeoutIdx);
    };
  }, [isLoading, timeoutIdx]);

  const [error, setError] = useState('');
  const [beError, setBeError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [checkedMessage, setCheckedMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      name: ''
    },
    onSubmit: async (values) => {
      const splitOnSpace = values.name.split(' ');
      const firstName = splitOnSpace[0];
      const lastName = splitOnSpace[splitOnSpace.length - 1];

      setIsLoading(true);

      if (values.email && values.name && values.password && values.confirmPassword) {
        try {
          setError('');
          setBeError('');
          setCheckedMessage('');

          let currErr = '';

          if (values.password !== values.confirmPassword) {
            setError('Passwords do not match');
            currErr = 'Passwords do not match';
          }

          if (values.password.length < 6) {
            setError('Password should be at least 6 characters');
            currErr = 'Password should be at least 6 characters';
          }

          if (!isChecked) {
            setCheckedMessage('You need to agree to the terms and conditions.');
            currErr = 'You need to agree to the terms and conditions.';
          }

          if (currErr) {
            return;
          }

          const response = await createUserWithEmailAndPassword(
            authentication,
            values.email,
            values.password
          );
          await updateProfile(response.user, {
            displayName: `${firstName} ${lastName}`
          });

          await authService.createUserInfo(response.user.uid, values.email, firstName, lastName);

          await signInWithEmailAndPassword(authentication, values.email, values.password);
          toast.success(`Account successfully created for ${values.email}`);
          const timeId = setTimeout(() => setIsLoading(false), 2000);
          setTimeoutIdx(timeId);
        } catch (err: any) {
          setBeError(err.code);
        }
      }
    }
  });

  return (
    <>
    <Sign>
      <form onSubmit={formik.handleSubmit} className="register">
        <h2>Sign Up</h2>
        {beError === 'auth/invalid-email' && (
          <span className="span__error-login">Invalid Email</span>
        )}
        <p>
          You already have an account?{' '}
          <span>
            <Link to="/signin">Sign in</Link>
          </span>{' '}
          here.
        </p>
        <label>Name</label>
        <input
          type="text"
          required
          id="name"
          name="name"
          placeholder="First and Last Name"
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        <label>E-mail</label>
        {beError === 'auth/email-already-in-use' ? (
          <div>
            <input
              type="email"
              id="email"
              className="error-login"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="name@email.com"
            />
            <span className="span__error-login">Email already exists</span>
          </div>
        ) : (
          <input
            type="email"
            required
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="name@email.com"
          />
        )}
        <label>Password</label>
        {error ? (
          <div className="flex flex-col gap-7">
            <input
              id="password"
              name="password"
              type="password"
              className="error-login"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <label>Confirm Password</label>
            <input
              type="password"
              className="error-login"
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              placeholder="6+ Characters"
            />
            <span className="span__error-login">{error}</span>
          </div>
        ) : (
          <div className="flex flex-col gap-7">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="6+ Characters"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              placeholder="6+ Characters"
            />
          </div>
        )}
        <div className="checkbox-register">
          <input type="checkbox" id="agree" onChange={(e) => setIsChecked(e.target.checked)} />
          <label htmlFor="agree">
            I agree to the terms of the{' '}
            <Link to="/tc" target="_blank">
              <span>License Agreement</span>
            </Link>{' '}
            and the{' '}
            <Link to="/policy" target="_blank">
              <span>Privacy Policy</span>
            </Link>
            .
          </label>
          {checkedMessage === 'You need to agree to the terms and conditions.' ? (
            <span className="span__error-login">{checkedMessage}</span>
          ) : null}
        </div>
        <button type="submit">Sign up</button>
        <div>
          <Toaster />
        </div>
      </form>
    </Sign>
    </>
  );
};

export default RegisterForm;
