import axios, { AxiosInstance } from 'axios';
import { ServerRequest } from '../types/request.types';

export default class AuthService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async sendActivationMail(email: string): Promise<number> {
    const response = await this.httpClient.post(`/Notification/email-activation?email=${email}`);
    return response.status;
  }

  async sendForgotPasswordMail(email: string): Promise<number> {
    const response = await this.httpClient.post(`/Notification/reset-password?email=${email}`);
    return response.status;
  }

  async sendMessage(
    email: string,
    fullName: string,
    message: string,
    subject = 'Contact Us'
  ): Promise<string> {
    const response = await this.httpClient.post('Notification/contact-us-email', {
      email,
      fullName,
      message,
      subject
    });
    return response.data;
  }

  async sendFeedback(
    FirebaseId : string,
    Email: string,
    FullName = "",
    Message : string,
    FeedBackMessage : string,
    Rating : string,
    Subject = 'Feedback',
    Device  = '',
    MacModel  = '',
    Display  = '',
    Backlight  = '',
    Error  = '',
    MacOS ="",
    MacBuild ="",
    WindowsBuild ="",
    WindowsOS ="",
    TimeStamp="",
    IosBuild ="",
    IosOS ="",
    ScreenSize="",
    Platform="",
  ): Promise<string> {
    const response = await this.httpClient.post('Notification/feedback-email', {
      FirebaseId,
      Email,
      FullName,
      Message,
      FeedBackMessage,
      Rating,
      Subject,
      Device,
      MacModel,
      Display,
      Backlight,
      Error,
      MacOS,
      MacBuild,
      WindowsBuild,
      WindowsOS,
      TimeStamp:TimeStamp.length>0?TimeStamp:null,
      IosBuild,
      IosOS,
      ScreenSize,
      Platform
    });
    return response.data;
  }

  async checkSubscription(): Promise<
    ServerRequest<{
      activeSubscription: boolean;
      isTrial: boolean;
      requestedToBeCancelled: boolean;
      stripeInfo: { stripeId: string; customerId: string };
    }>
  > {
    const response = await this.httpClient.get('/Subscription/check-subscription-validation');
    return response.data;
  }

  async getSubscriptionInfo(): Promise<
    ServerRequest<{
      startDate: string;
      endDate: string;
      renewDate: string;
      requestToCancelDate: string;
      stripeCardPlaceHolder: string;
      stripeCardName: string;
      stripePortalUrl: string;
    }>
  > {
    const response = await this.httpClient.get('/Subscription/get-subscription-info');
    return response.data;
  }

  async userExistsCheck(firebaseId: string): Promise<ServerRequest<any>> {
    const response = await this.httpClient.get(
      `/UsersInfo/user-exists-check?firebaseId=${firebaseId}`
    );
    return response.data;
  }

  async feedbackSentCheck(firebaseId: string): Promise<ServerRequest<any>> {
    const response = await this.httpClient.get(
      `/UsersInfo/feedback-sent-check?firebaseId=${firebaseId}`
    );
    return response.data;
  }

  async createUserInfo(
    firebaseId: string,
    email: string,
    firstName: string,
    lastName: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('/UsersInfo/create-user-info', {
      firebaseId,
      email,
      firstName,
      lastName
    });

    return response.data;
  }

  async editUsername(
    firstName: string,
    lastName: string,
    firebaseId: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.put('UsersInfo/edit-user-name', {
      firstName,
      lastName,
      firebaseId
    });

    return response.data;
  }

  async addToWaitList(
    email: string,
    preferredOs: string,
    preferredMobile: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('Notification/add-to-waitlist', {
      email,
      preferredOs,
      preferredMobile
    });

    return response.data;
  }

  async getUserStatus(): Promise<ServerRequest<any>> {
    const response = await this.httpClient.get('UsersInfo/status');

    return response.data;
  }
}

export const authService = new AuthService(axios);
