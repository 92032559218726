import React from 'react';
import {FeedbackData} from "../../types/request.types";

type Action =
  | { type: 'set_page'; payload: number }
  | { type: 'set_size'; payload: number }
  | { type: 'set_total_elements'; payload: number }
  | { type: 'set_type_and_reset_page'; payload: number }
  | { type: 'set_data'; payload: FeedbackData[] }

type Dispatch = (action: Action) => void;
type State = {
  page: number,
  size: number,
  totalElements: number,
  type: number,
  data: FeedbackData[],
};
type FeedbackProviderProps = { children: React.ReactNode };

const FeedbackContext = React.createContext<{ state: State; dispatch: Dispatch; computed: any } | undefined>(
  undefined
);

function feedbackReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set_page':
      return { ...state, page: action.payload };
    case 'set_size':
      return { ...state, size: action.payload };
    case 'set_total_elements':
      return { ...state, totalElements: action.payload };
    case 'set_type_and_reset_page':
      return { ...state, page: 0, type: action.payload };
    case 'set_data':
      return { ...state, data: action.payload };
    default: {
      return state;
    }
  }
}

function FeedbackProvider({ children }: FeedbackProviderProps) {
  const [state, dispatch] = React.useReducer(feedbackReducer, {
    page: 0,
    size: 40,
    totalElements: 0,
    type: 3,
    data: [],
  });

  const computed = {
    oneBasedPage: state.page + 1,
    start: state.page * state.size,
  }

  const value = { state, computed, dispatch };
  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
}

function useFeedback() {
  const context = React.useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
}

export { FeedbackProvider, useFeedback };
