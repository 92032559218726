import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUser } from '../../contexts/userContext';
import Layout from '../../components/Layout';
import userIcon from '../../assets/icons/78-user.svg';
import card from '../../assets/icons/card.svg';
import userinfoCircles from '../../assets/icons/userinfo-circles.svg';
import subscriptionCircles from '../../assets/icons/subscription-circles.svg';
import '../../Styles/UserInfo.scss';

const PersonalInformation = (props: any) => {
  const location = useLocation();
  const {
    state: { subscribed }
  } = useUser();
  return (
    <Layout>
      <div className='user__page'>
        <img src={location.pathname === '/subscription' ? subscriptionCircles : userinfoCircles} alt="" className="userinfoCircles"/>
        <div className="user__page-links">
          <p>My account</p>
          <Link to='/account' className={location.pathname === '/account' ? 'colored-link' : ''}><img src={userIcon} alt="" className="icon-userinfo" />Personal Info</Link>
          {
            subscribed ? (
              <Link to='/subscription' className={location.pathname === '/subscription' ? 'colored-link' : ''}><img src={card} alt="" className="icon-userinfo" />Subscription Info</Link>
            ) : null
          }
        </div>
        <div className="user__page-child">
          {props.children}
        </div>
      </div>
    </Layout>
  );
}

export default PersonalInformation;