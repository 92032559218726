import React from 'react';
import Layout from '../../components/Layout';
import '../../Styles/About.scss';
import aboutTruhu from '../../assets/webp_images/about_truhu.webp';
import aboutSeboya from '../../assets/webp_images/sign_in_img.webp';
import { Helmet } from 'react-helmet';

import logo from '../../assets/icons/Logo.svg';

const About = () => {
  return (
    <Layout>
      <div className="about">
        <Helmet>
          <title>About TruHu | True Color Made Easy</title>
          <meta
            name="description"
            content="​Nearly everyone carries a color measurement instrument in their pocket. We took advantage of it and built an app that helps you calibrate your displays."
          />
        </Helmet>
        <div className="first-section__about">
          <div className="first-section-text__about">
            <h2>We are TruHu</h2>
            <p>
              While the world of digital color has improved a lot in the last 10-20 years - better
              cameras, design software, displays, printers - there&apos;s one area that has notably
              fallen short: on screen color accuracy. The available colorimeter-based monitor
              calibration solutions are either too expensive or too difficult to use and have not
              been widely adopted.
            </p>
          </div>
          <div className="aboutTruhu">
            <img src={aboutTruhu} alt="" />
          </div>
        </div>
        <div className="second-section__about">
          <div className="aboutSeboya">
            <img src={aboutSeboya} alt="" />
          </div>
          <div className="second-section-text__about">
            <h2>
              We made <img src={logo} alt="" className="logo__about" />
            </h2>
            <p>
              One day we had a useful insight. Nearly everyone carries a color measurement
              instrument in their pocket. It&apos;s called a smartphone. With a little sleight of
              hand, it&apos;s possible to turn a smartphone&apos;s camera into an accurate color
              measurement reader. Add some user-friendly software, and you have an easy-to-use,
              affordable screen calibrator for everyone - TruHu.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
