import React from 'react';
// import queryString from 'query-string';
import ResetPassword from './ResetPassword';
import Sign from '../../components/Sign/Sign';
import { useHistory, useLocation } from 'react-router-dom';

const AuthAction = () => {
  const location = useLocation();
  const history = useHistory();
  const params = {
    mode: location.search.split('&')[1].split('=')[1],
    oobCode: location.search.split('&')[2].split('=')[1]
  };

  if (params.mode === 'resetPassword') {
    return <ResetPassword oobCode={params.oobCode as string} />;
  } else {
    return (
      <Sign>
        <div className="flex items-center justify-center flex-col w-100 bg-gray-100 p-8 rounded-md">
          <h3 className="text-xl">Invalid action, go to login and try again</h3>
          <button
            type="submit"
            className="mt-3 rounded bg-orange-200 hover:bg-orange-300 py-1 px-2 duration-200"
            onClick={() => {
              history.push('/');
            }}>
            Go to login
          </button>
        </div>
      </Sign>
    );
  }
};

export default AuthAction;
