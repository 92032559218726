import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { authService } from '../../services/auth.service';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import circles_contact from '../../assets/icons/circles_contact.svg';
import elipse_contact from '../../assets/icons/elipse_contact.svg';
import checkMark from '../../assets/icons/check-mark.svg';
import girlContact from '../../assets/webp_images/girl_contact.webp';
import '../../Styles/Contact.scss';
//?type=feedback&mode=Release&temperature=6500&device=iPhone%2012%20Pro%20Max&display=LG%20HDR%204K&backlight=W-LED&timeStamp=2023-02-06-15-39&email=scott%40bitwyze.org&macModel=MacBookAir10,1

const FeedbackReport = () => {
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const authentication = getAuth(firebaseApp);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const messageType = decodeURI(query.get('type') || '');
  // const mode = decodeURI(query.get('mode') || '');
  const temperature = decodeURI(query.get('temperature') || '');
  const device = decodeURI(query.get('device') || '');
  const display = decodeURI(query.get('display') || '');
  const backLight = decodeURI(query.get('backlight') || '');
  const errorCount = decodeURI(query.get('error') || '') || '0';
  const timeStamp = decodeURI(query.get('timeStamp') || '');
  const urlEmail = decodeURI(query.get('email') || '');
  const macModel = decodeURI(query.get('macModel') || '');
  const iosBuild = decodeURI(query.get('iOSBuild') || '');
  const iOSVersion = decodeURI(query.get('iOS') || '');
  const macOS = decodeURI(query.get('macOS') || '');
  const macBuild = decodeURI(query.get('macBuild') || '');
  const winBuild = decodeURI(query.get('winBuild') || '');
  const winOS = decodeURI(query.get('winOS') || '');
  const iosOS = decodeURI(query.get('iOS') || '');
  const screenSize = decodeURI(query.get('screenSize') || '');
  const platform = decodeURI(query.get('platform') || '');
  const userName = decodeURI(query.get('userName') || '');
  // Mode: \t\t${mode}

  let additionalInfo = '';

  if (urlEmail) additionalInfo += `User: \t\t\t${urlEmail}\n`;

  if (userName) additionalInfo += `Name: \t\t\t${userName}\n`;

  if (platform) additionalInfo += `Platform: \t\t${platform}\n`;

  if (macBuild) additionalInfo += `Mac Build: \t\t${macBuild}\n`;

  if (macModel) additionalInfo += `Mac Model: \t\t${macModel}\n`;

  if (macOS) additionalInfo += `Mac OS: \t\t${macOS}\n`;

  if (winBuild) additionalInfo += `Windows Build: \t\t${winBuild}\n`;

  if (winOS) additionalInfo += `Windows OS: \t\t${winOS}\n`;

  if (screenSize) additionalInfo += `Screen Size: \t\t${screenSize}\n`;

  if (device) additionalInfo += `Device: \t\t${device}\n`;

  if (iosBuild) additionalInfo += `IOS Build: \t\t${iosBuild}\n`;

  if (iOSVersion) additionalInfo += `IOS Version: \t${iOSVersion}\n`;

  if (display) additionalInfo += `Display: \t\t${display}\n`;

  if (temperature) additionalInfo += `Temperature: \t${temperature}\n`;

  if (backLight) additionalInfo += `Backlight: \t\t${backLight}\n`;

  if (timeStamp) additionalInfo += `Timestamp: \t\t${timeStamp}\n`;

  additionalInfo += `${messageType === 'error' ? `Error #:\t\t${errorCount}` : ''}\n`;
  additionalInfo += `${messageType === 'error' ? 'Error Description:' : 'Profile Feedback:'}\n`;
  const formik = useFormik({
    initialValues: {
      // email: urlEmail,
      fullName: userName || '',
      message: ''
    },
    onSubmit: async (values) => {
      try {
        setError('');
        setSubmitted(true);
        await authService.sendFeedback(
          authentication.currentUser === null ? '' : authentication.currentUser?.uid,
          urlEmail,
          values.fullName,
          `${additionalInfo}${values.message}`,
          `${values.message}`,
          '',
          messageType === 'error' ? 'Error Report' : 'TruHu Feedback',
          device,
          macModel,
          display,
          backLight,
          errorCount,
          macOS,
          macBuild,
          winBuild,
          winOS,
          timeStamp,
          iosBuild,
          iosOS,
          screenSize,
          platform
        );
      } catch (err: any) {
        setError(err.code);
      }
    }
  });
  const title = messageType === 'error' ? 'Error Report' : 'TruHu Feedback';
  const subtitle =
    messageType === 'error'
      ? `Thank you for reporting this error.
Your report will help us to improve TruHu!`
      : `Thank you for providing feedback on your TruHu experience
Your feedback will help us to improve TruHu`;

  return (
    <Layout>
      <div className="contact__page">
        <img src={circles_contact} alt="" className="circles_contact" />
        <div className="contact__flex">
          <div className="form__contact feedback-report">
            <img src={elipse_contact} alt="" className="elipse_contact" />
            {submitted ? (
              <div className="contact__submitted">
                <h2>{messageType === 'error' ? 'Error Report' : 'Feedback'} Sent</h2>
                <img src={checkMark} alt="" className="check_mark" />
                <p>
                  Thank you for your feedback. We are striving to improve our product and your
                  experience, so your input is much appreciated.
                </p>
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="contact__header feedback-report">
                  <h2>{title}</h2>
                  <p className="pre-line">{subtitle}</p>
                  <span>{error}</span>
                </div>
                <p className="bold">
                  {messageType === 'error' ? 'Error Information' : 'Calibration Information'}
                </p>
                <div className="contact__info">
                  {messageType === 'error' && (
                    <p>
                      Error#: <span className="bold">{errorCount}</span>
                    </p>
                  )}
                  {platform && (
                    <p>
                      Platform : <span className="bold">{platform}</span>
                    </p>
                  )}
                  {macBuild && (
                    <p>
                      Mac Build: <span className="bold">{macBuild}</span>
                    </p>
                  )}
                  {winBuild && (
                    <p>
                      Windows Build: <span className="bold">{winBuild}</span>
                    </p>
                  )}
                  {winOS && (
                    <p>
                      Windows OS: <span className="bold">{winOS}</span>
                    </p>
                  )}
                  {macOS && (
                    <p>
                      Mac OS Version: <span className="bold">{macOS}</span>
                    </p>
                  )}
                  {iosBuild && (
                    <p>
                      iOS Build: <span className="bold">{iosBuild}</span>
                    </p>
                  )}
                  {iOSVersion && (
                    <p>
                      iOS Version: <span className="bold">{iOSVersion}</span>
                    </p>
                  )}

                  {urlEmail && (
                    <p>
                      Email: <span className="bold">{urlEmail}</span>
                    </p>
                  )}
                  {device && (
                    <p>
                      Device: <span className="bold">{device}</span>
                    </p>
                  )}
                  {macModel && (
                    <p>
                      Mac Model: <span className="bold">{macModel}</span>
                    </p>
                  )}
                  {display && (
                    <p>
                      Display: <span className="bold">{display}</span>
                    </p>
                  )}
                  {screenSize && (
                    <p>
                      screen Size: <span className="bold">{screenSize}</span>
                    </p>
                  )}
                  {backLight && (
                    <p>
                      Backlight: <span className="bold">{backLight}</span>
                    </p>
                  )}
                  {temperature && (
                    <p>
                      Temperature: <span className="bold">{temperature}</span>
                    </p>
                  )}
                  {timeStamp && (
                    <>
                      <p>
                        Date: <span className="bold">{timeStamp.slice(0, 10)}</span>
                      </p>
                      <p>
                        Time:{' '}
                        <span className="bold">{timeStamp.slice(11).split('-').join(':')}</span>
                      </p>
                    </>
                  )}
                </div>
                <div className="contact__input">
                  <input
                    type="text"
                    name="fullName"
                    required
                    id="fullName"
                    placeholder="Enter your name"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                  />
                </div>
                <div className="textarea__input">
                  <textarea
                    name="message"
                    id="message"
                    placeholder={
                      messageType === 'error'
                        ? 'Provide all relevant information leading up to this error'
                        : 'Provide your feedback here'
                    }
                    onChange={formik.handleChange}
                    value={formik.values.message}></textarea>
                </div>
                <button type="submit">Send</button>
              </form>
            )}
          </div>
        </div>
        <div className="contact__img">
          <img src={girlContact} alt="" className="girlContact" />
        </div>
      </div>
    </Layout>
  );
};

export default FeedbackReport;
