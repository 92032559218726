import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { UserProvider } from './contexts/userContext';
import { FeedbackProvider } from './contexts/feedbackContext';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'tw-elements';
import './index.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <FeedbackProvider>
      <UserProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserProvider>
    </FeedbackProvider>
  </Elements>,
  document.getElementById('root')
);
