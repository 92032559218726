import React from 'react';
import { Link } from 'react-router-dom';
import mainLogo from '../../assets/icons/Logo.svg';
import Footer from '../Footer/Footer';
import signCircles from '../../assets/icons/SignCircles.svg';
import '../../Styles/Sign.scss';

const Sign = (props: any) => {
  return (
    <div className="sign__page">
      <div className="sign__header">
        <Link to="/">
          <img src={mainLogo} alt="logo" className="logo__header" />
        </Link>
      </div>
      <img src={signCircles} alt="" className="sign__circles" />
      <div className="sign__flex">
        <div className="form">{props.children}</div>
        <div className="sign__img"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Sign;
