import { useState, useEffect } from 'react';

type DeviceInfo = {
  device: string;
  platform: string;
};

export const usePlatformDetect = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    device: '',
    platform: ''
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // const isAndroid = userAgent.match(/Android/i);

    // const isAppleMobile = userAgent.match(/iPhone|iPad|iPod/i);

    const isMacOS = Boolean(userAgent.match(/Macintosh|Intel Mac OS X/i));
    const isWindowsOS = Boolean(userAgent.match(/Windows|Win64|Win32/i));

    setDeviceInfo({
      device: deviceInfo.device,
      platform: isWindowsOS ? 'Windows' : isMacOS ? 'MacOs' : ''
    });

    const msStream: any = (window as any).MSStream;
    if (/android/i.test(userAgent)) {
      setDeviceInfo({
        device: 'Android',
        platform: deviceInfo.platform
      });
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !msStream) {
      setDeviceInfo({
        device: 'AppleDevice',
        platform: deviceInfo.platform
      });
    } else {
      console.log('Unknown');
    }
  }, []);

  return { deviceInfo };
};
