import React, { useState } from 'react';
import Sign from '../../components/Sign/Sign';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

const ResetPassword: React.FC<{ oobCode: string }> = ({ oobCode }) => {
  const auth = getAuth(firebaseApp);
  const history = useHistory();

  const [passwordReseted, setPasswordReseted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    onSubmit: async (values) => {
      setLoading(true);

      if(values.confirmPassword && values.password) {
        try {
          setError('');

          if(values.password !== values.confirmPassword) {
            setLoading(false);
            return setError("Passwords don't match");
          }
    
          if(values.password.length < 6) {
            setLoading(false);
            return setError('Password should be at least 6 characters')
          }    

          if(values.password === values.confirmPassword) { 
            await confirmPasswordReset(auth, oobCode, values.password);
            setPasswordReseted(true);
          } 
          setLoading(false);
        } catch(err: any) {
          setError(err);
        }
      }
    }
  });

  if (passwordReseted) {
    return (
      <Sign>
        <div className="flex items-center justify-center flex-col w-100 p-8 rounded-md">
          <h1 className="text-password-reset">Password reset, please return and login</h1>
          <button
            type="submit"
            onClick={() => {
              history.push('/');
            }}>
            Go to login
          </button>
        </div>
      </Sign>
    );
  }

  return (
    <Sign>
        <form onSubmit={formik.handleSubmit}>
          <h2>Enter your new password</h2>
          <label htmlFor="password">New password</label>
          {
            error ? (
              <div className='flex flex-col gap-7'>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className='error-login'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="6+ Characters"
                />
                <label>Confirm Password</label>
                <input type="password" className='error-login' name="confirmPassword" onChange={formik.handleChange} value={formik.values.confirmPassword} placeholder="6+ Characters"/>
                <span className='span__error-login'>{error}</span>
              </div>
            ) : (
              <div className='flex flex-col gap-7'>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="6+ Characters"
                />
                <label>Confirm Password</label>
                <input type="password" name="confirmPassword" onChange={formik.handleChange} value={formik.values.confirmPassword} placeholder="6+ Characters"/>
              </div>
            )
          }
          <button
            disabled={!formik.values.password || !oobCode}
            type="submit">
            {loading ? (
                <p className="text-center">
                  Loading...
                </p>
              ) : (
                'Submit'
              )}
          </button>
        </form>
    </Sign>
  );
};

export default ResetPassword;
