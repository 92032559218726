export default function downloadFile(
  data: BufferSource | Blob | string,
  title: string,
  mediaType: string,
  charset = 'charset=utf-8',
): void {
  const blob = new Blob([data], { type: `${mediaType};${charset}` });
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  const objectURL = window.URL.createObjectURL(blob);
  a.href = objectURL;
  a.download = title;
  a.click();
  window.URL.revokeObjectURL(objectURL);
  document.body.removeChild(a);
}
