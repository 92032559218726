import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { useUser } from '../../contexts/userContext';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import Sign from '../../components/Sign/Sign';
import checkMark from '../../assets/icons/check-mark.svg';

const ForgotPasswordForm = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { state: user } = useUser();
  const authentication = getAuth(firebaseApp);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async (values) => {
      if (values.email) {
        setLoading(true);
        try {
          setError('');
          if (user.isLoggedIn) {
            await authService.sendForgotPasswordMail(authentication.currentUser?.email || '');
          } else {
            await authService.sendForgotPasswordMail(values.email);
          }
          setEmailSent(true);
          setLoading(false);
        } catch (err: any) {
          setError(err.response.data.errors[0].completeMessage);
          setLoading(false);
        }
      } else {
        setError('Please enter your e-mail');
      }
    }
  });

  return (
    <Sign>
      {emailSent ? (
        <div className="reset__confirmation">
          <p>Reset password mail has been sent</p>
          <img src={checkMark} alt="" className="check_mark" />
          <p>Please check your inbox</p>
        </div>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} className="forgot">
            <div className="forgot__form">
              <h2>Enter your email to reset your password</h2>
              <label htmlFor="email">Email Address</label>
              {error ? (
                <div>
                  <input
                    type="email"
                    name="email"
                    className="error-login"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="name@email.com"
                  />
                  <span className="span__error-login">{error}</span>
                </div>
              ) : (
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="name@email.com"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              )}
              <button type="submit">
                {loading ? <p className="text-center">Loading...</p> : 'Submit'}
              </button>
              <h4
                onClick={() => {
                  history.push('/signin');
                }}
                className="cursor-pointer">
                Go back to login
              </h4>
            </div>
          </form>
        </>
      )}
    </Sign>
  );
};

export default ForgotPasswordForm;
