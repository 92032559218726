import React from "react";
import '../../Styles/Faq.scss';
import Layout from '../../components/Layout';
import circles1_faq from '../../assets/icons/circles1_faq.svg';
import circles2_faq from '../../assets/icons/circles2_faq.svg';

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className='faq__page'>
          <img src={circles1_faq} alt="" className="circles1_faq"/>
          <img src={circles2_faq} alt="" className="circles2_faq"/>
        <h2 className="tc-title">License agreement for TruHu software by Seboya, LP</h2>
        <div className="faq__questions">
          <div className="faq__section">
            <p>
              IMPORTANT: READ THIS AGREEMENT CAREFULLY. BY SELECTING THE &quot;AGREE&quot; BUTTON, INSTALLING, COPYING OR OTHERWISE USING THE TRUHU SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS LICENSE.
            </p>
            <p>
              This license agreement (&quot;License&quot;) is a legal agreement between you and Seboya, LP. (&quot;Seboya&quot;) granting you certain rights to access and use the TruHu Software owned by Seboya (the &quot;Software&quot;). YOU HEREBY AGREE, BOTH ON YOUR OWN BEHALF AND AS AN AUTHORIZED REPRESENTATIVE OF ANY ORGANIZATION FOR WHICH YOU ARE USING THE SOFTWARE (&quot;EMPLOYER&quot;), THAT YOU AND YOUR EMPLOYER WILL USE THE SOFTWARE ONLY IN ACCORDANCE WITH THE FOLLOWING TERMS:
            </p>
          </div>
          <div className="faq__section">
            <h4>1. Disclaimer of Warranty on Software.</h4>
            <p>You expressly acknowledge and agree that use of the Software is at your sole risk.</p>
            <p>
              THE SOFTWARE IS PROVIDED &quot;AS IS&quot;, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. 
            </p>
            <p>
              Seboya does not warrant that the functions contained in the Software will meet your requirements, or that the operation of the Software will be uninterrupted or error-free, or that defects in the Software will be corrected. Furthermore, Seboya does not warrant or make any representation regarding the use or the results of the use of the Software or related documentation in terms of their correctness, accuracy, reliability or otherwise. Should the Software prove defective, you (and not Seboya or a Seboya authorized representative) assume the entire cost of all necessary servicing, repair or correction. Some states do not allow the exclusion or limitation of implied warranties, so the above exclusion may not apply to you. The terms of this disclaimer do not affect or prejudice the statutory rights of a consumer acquiring Seboya products otherwise than in the course of a business; neither do they limit or exclude any liability for death or personal injury caused by Seboya&apos;s negligence. 
            </p>
            <p>
              If you have purchased the Software for business or commercial use, you also agree that Seboya EXPRESSLY DISCLAIMS ANY WARRANTY OF MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT WITH RESPECT TO THE SOFTWARE. 
            </p>
          </div>
          <div className="faq__section">
            <h4>2. Limitation of Liability.</h4>
            <p>
              UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL Seboya BE LIABLE FOR ANY INCIDENTAL, SPECIAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO THIS LICENSE OR THE SOFTWARE. 
            </p>
            <p>
              Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages so the above limitation may not apply to you. In no event shall Seboya&apos;s total liability to you for all damages exceed the one-year subscription price of the software. The foregoing limitation will apply even if the above stated remedy fails of its essential purpose.
            </p>
          </div>
          <div className="faq__section">
            <h4>3. License Grant.</h4>
            <p>
              With respect to TruHu mobile software, Seboya grants to you, and you accept, a personal, nonexclusive, nontransferable license to install and use the Software in object code format on one or more mobile devices running iOS® or Android® software. 
            </p>
            <p>
              With respect to TruHu desktop software running MacOS® or Windows® software, Seboya grants to you, and you accept, a personal, nonexclusive, nontransferable license to install and use the Software in object code format on a single computer at a time.            
            </p>
            <p>
              In addition to installed copies, you may make one copy of the Software in machine-readable form only for backup purposes. The backup copy must include all copyright information contained on the original. 
            </p>
            <p>
              Seboya grants to you, and you accept, a personal, nonexclusive, nontransferable license to distribute profiles in .ICM or .ICC format (&quot;Profiles&quot;), including to customers of your consulting or design services, so long as you do not otherwise sell, lease, or rent Profiles, nor bundle Profiles with any hardware, software or other commercial product. 
            </p>
            <p>
              This License is effective until terminated as provided herein. You may terminate this License by destroying the Software and all copies of the Software in your possession. This License will terminate automatically upon any violation of the terms of this License. You acknowledge that this License does not entitle you to any support, maintenance or upgrade from Seboya. 
            </p>
          </div>
          <div className="faq__section">
            <h4>4. License Restrictions.</h4>
            <p>
              You may not do any of the following yourself, or through any other person and you may not permit any third party with whom you have a business relationship to do any of the following: (A) copy, modify or create derivative works based upon the Software; (B) decompile, disassemble, or reverse engineer the Software in whole or in part; (C) defeat, disable or circumvent any protection mechanism related to the Software; (D) sell, license, sublicense, lease, rent, distribute, disclose, permit access to, or transfer to any third party, whether for profit or without charge, any portion of the Software or any product (as a separate product or bundled with any other products other than the Software) created with the Software (except Profiles, which may be distributed only as set forth in Section 4 above), or, in particular, without limiting the generality of the foregoing, distribute the Software or any such product on any media, over networks, electronic bulletin boards, or websites, or allow any third party to use the Software; (E) export, use or download the Software in violation of any laws or regulations, including U.S. Department of Commerce Export Administration regulations and other applicable laws or (F) use the Software in connection with life support systems, human implantation, medical devices, nuclear facilities, nuclear systems or weapons, aviation, mass transit or any applications where failure or malfunction could lead to possible loss of life or catastrophic property damage.
            </p>
          </div>
          <div className="faq__section">
            <h4>5. Title and Ownership.</h4>
            <p>
              Except for the rights expressly granted above, this License transfers to you no right, title, or interest in the Software, any product (including, without limitation, Profiles) created with the Software or any copyright, patent, trademark, trade secret or other intellectual property or proprietary right in the Software or any such product. Seboya retains sole and exclusive title to all portions of the Software and any copies thereof, and you hereby assign to Seboya all right, title, and interest in and to any modifications you make to the Software and any product you make with the Software, whether or not such modifications are permitted. You agree not to disclose the Software to anyone.
            </p>
          </div>
          <div className="faq__section">
            <h4>6. Export Law Assurances.</h4>
            <p>
              You may not use or otherwise export or reexport the Software except as authorized under the laws of the jurisdiction in which the Software was obtained.
            </p>
          </div>
          <div className="faq__section">
            <h4>7. Government End Users.</h4>
            <p>
              If the Software is supplied to the United States Government, the Software is classified as &quot;restricted computer software&quot; as defined in clause 52.227-19 of the FAR. The United States Government&apos;s rights to the Software are as provided in clause 52.227-19 of the FAR.
            </p>
          </div>
          <div className="faq__section">
            <h4>8. Controlling Law and Severability.</h4>
            <p>
              This License shall be governed by the laws of the United States and the State of California. You irrevocably submit to the jurisdiction of any state or federal court sitting in San Francisco, CA and any action or proceeding arising out of this License will be heard and determined in such court. If for any reason a court of competent jurisdiction finds any provision, or portion thereof, to be unenforceable, the remainder of this License shall continue in full force and effect.
            </p>
          </div>
          <div className="faq__section">
            <h4>9. Complete Agreement.</h4>
            <p>
              This License constitutes the entire agreement between the parties with respect to the use of the Software and supersedes all prior or contemporaneous understandings regarding such subject matter. No amendment to or modification of this License will be binding unless in writing and signed by Seboya.
            </p>
            <p>
              BY SELECTING THE &quot;AGREE&quot; BUTTON, INSTALLING, COPYING OR OTHERWISE USING THE TRUHU SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS LICENSE.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;