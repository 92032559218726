import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { configureServices } from './services';
import { useUser } from './contexts/userContext';
import { getAuth, onAuthStateChanged, Unsubscribe } from 'firebase/auth';
import { firebaseApp } from './firebase-config';
import { authService } from './services/auth.service';
import { PrivateRoute } from './components/PrivateRoute';
import Payment from './pages/Payment';
import AuthAction from './pages/AuthAction';
import Landing from './pages/Landing';
import About from './pages/About';
import Product from './pages/Product';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import LoginForm from './pages/Authentication/LoginForm';
import RegisterForm from './pages/Authentication/RegisterForm';
import Feedback from './pages/Feedback';
import PersonalInformation from './pages/Account/PersonalInformation';
import SubscriptionPage from './pages/Account/SubscriptionPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Subscribed from './pages/Subscribed';
import DownloadBeta from './pages/DownloadBeta';
import NotFound from './pages/404';
import FeedbackReport from './pages/FeedbackReport';
import Admin from './pages/Admin';
import WaitList from './pages/WaitList';
import DemoSubscriptions from './pages/Invites';
import ForgotPasswordForm from './pages/Authentication/ForgotPasswordForm';
import './App.css';


configureServices(process.env.REACT_APP_BACKEND_URL || '');

const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>;
};

function App() {
  const auth = getAuth(firebaseApp);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    state: { isLoggedIn },
    dispatch
  } = useUser();

  let unsubscribe: Unsubscribe;

  const authentication = getAuth(firebaseApp);

  const checkFeedback = async () => {
    if (authentication.currentUser === null) {
      return;
    }
    const response = await authService.feedbackSentCheck(authentication.currentUser.uid);

    if (response.responseObject === true) {
      dispatch({ type: 'set_feedback', payload: true });
    }

    if (response.responseObject === false) {
      dispatch({
        type: 'set_feedback',
        payload: false
      });
    }
  };

  const checkSubscription = async () => {
    const response = await authService.checkSubscription();

    if (response.responseObject.activeSubscription === true) {
      dispatch({ type: 'set_subscribed', payload: true });
      dispatch({
        type: 'set_subscription_id',
        payload: response.responseObject.stripeInfo.stripeId
      });
      dispatch({ type: 'set_is_subscribed', payload: true });
    }

    if (response.responseObject.activeSubscription === false) {
      dispatch({
        type: 'set_subscribed',
        payload: false
      });
      dispatch({
        type: 'set_is_subscribed',
        payload: false
      });
    }

    if (response.responseObject.isTrial === true) {
      dispatch({
        type: 'set_trial',
        payload: true
      });
    } else {
      dispatch({
        type: 'set_trial',
        payload: false
      });
    }

    if (response.responseObject.requestedToBeCancelled) {
      dispatch({
        type: 'set_request_cancelled',
        payload: true
      });
    }
  };
  const checkUserStatus = async () => {
    try {
      const {
        responseObject: { isAdmin = false, hasBetaAccess = false }
      } = await authService.getUserStatus();

      dispatch({ type: 'set_user_status', payload: { isAdmin, hasBetaAccess } });
    } catch (e) {
      console.error(e);
      dispatch({ type: 'set_user_status', payload: { isAdmin: false, hasBetaAccess: false } });
    }
  };

  useEffect(() => {
    if (isLoggedIn === false) {
      // Firebase auth listener
      unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch({
            type: 'set_logged',
            payload: {
              isLoggedIn: true
            }
          });
          unsubscribe();
        }
        setDataLoaded(true);
      });
    } else {
      checkUserStatus();
      checkSubscription();
      checkFeedback();
    }
  }, [isLoggedIn]);

  if (!dataLoaded) {
    return null;
  }

  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/auth-action">
          <AuthAction />
        </Route>
        <Route exact path="/payment/:paymentType">
          <Payment />
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/product">
          <Product />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/tc">
          <TermsAndConditions />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/account">
          <PersonalInformation />
        </Route>
        <Route path="/subscription">
          <SubscriptionPage />
        </Route>
        <Route path="/signin">
          <LoginForm />
        </Route>
        <Route path="/reset-password">
          <ForgotPasswordForm />
        </Route>
        <Route path="/signup">
          <RegisterForm />
        </Route>
        <Route path="/feedback-report">
          <FeedbackReport />
        </Route>
        <Route path="/wait-list">
          <WaitList />
        </Route>
        <Route path="/download">
          <DownloadBeta />
        </Route> 
        <Route path="/downloads">
          <DownloadBeta />
        </Route>
        <Route path="/success">
          <Subscribed />
        </Route>
        <PrivateRoute path="/reports-admin">
          <Admin />
        </PrivateRoute>
        <PrivateRoute path="/invites-admin">
          <DemoSubscriptions />
        </PrivateRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </ScrollToTop>
  );
}

export default App;
