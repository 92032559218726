import React from 'react';

type Action =
  | { type: 'set_subscribed'; payload: boolean }
  | { type: 'set_subscription_id'; payload: string }
  | { type: 'set_logged'; payload: { isLoggedIn: boolean } }
  | { type: 'set_stripe_customer_id'; payload: string }
  | { type: 'set_is_subscribed'; payload: boolean }
  | { type: 'set_request_cancelled'; payload: boolean}
  | { type: 'set_feedback'; payload: boolean}
  | { type: 'set_trial'; payload: boolean}
  | { type: 'set_admin_status'; payload: boolean}
  | { type: 'set_beta_access'; payload: boolean}
  | { type: 'set_user_status'; payload: {hasBetaAccess: boolean, isAdmin: boolean}}
  | { type: 'logout' };

type Dispatch = (action: Action) => void;
type State = {
  subscribed: boolean;
  subscriptionId: string;
  isLoggedIn: boolean;
  customerStripeId: string;
  isSubscribed: boolean;
  isCancelled: boolean;
  feedback: boolean;
  trial: boolean;
  isAdmin: boolean | null;
  hasBetaAccess: boolean | null;
};
type UserProviderProps = { children: React.ReactNode };

const UserContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set_subscribed':
      return { ...state, subscribed: action.payload };
    case 'set_subscription_id':
      return { ...state, subscriptionId: action.payload };
    case 'set_logged':
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn
      };
    case 'set_is_subscribed':
      return {
        ...state,
        isSubscribed: action.payload
      }
    case 'set_feedback':
      return {
        ...state,
        feedback: action.payload
      }
    case 'set_trial':
      return {
        ...state,
        trial: action.payload
      }
    case 'set_request_cancelled':
      return {
        ...state,
        isCancelled: action.payload
      }
    case 'logout':
      return {
        ...state,
        isLoggedIn: false,
        customerStripeId: '',
        subscriptionId: '',
        subscribed: false,
        hasBetaAccess: null,
        isAdmin: null,
        //token
      };
    case 'set_stripe_customer_id':
      return {
        ...state,
        customerStripeId: action.payload
      };
    case 'set_admin_status':
      return {
        ...state,
        isAdmin: action.payload
      };
    case 'set_beta_access':
      return {
        ...state,
        hasBetaAccess: action.payload
      };
    case 'set_user_status':
      return {
        ...state,
        hasBetaAccess: action.payload.hasBetaAccess,
        isAdmin: action.payload.isAdmin
      };
    default: {
      return state;
    }
  }
}

function UserProvider({ children }: UserProviderProps) {
  const [state, dispatch] = React.useReducer(userReducer, {
    subscribed: false,
    subscriptionId: '',
    isLoggedIn: false,
    customerStripeId: '',
    isSubscribed: false,
    isCancelled: false,
    feedback: false,
    trial: false,
    isAdmin: null,
    hasBetaAccess: null,
  });
  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
}

export { UserProvider, useUser };
