import React from 'react'
import { Link } from 'react-router-dom'

import circles1 from '../../assets/icons/circles1.svg';
import circles2 from '../../assets/icons/circle2.svg';
import phoneImage from '../../assets/webp_images/hand_phone_hero.webp';
import monitorImage from '../../assets/webp_images/monitor_hero.webp';
const Banner = () => {
  return (
    <div className="first-section__landing">
    <div className="first-section-text__landing">
      <div>
        <h1 style={{ marginBottom: '=5px' }}>Monitor Color Calibration Tool</h1>
        <h2>
          Good color
          <br /> made easy
        </h2>
      </div>
      <p>
        Achieve professional color accuracy with TruHu’s
        <br />
        app-based calibration tool in a few simple steps.
      </p>
      <Link to="/product">
        <button>Get started</button>
      </Link>
      <img src={circles2} alt="" className="circles2" />
    </div>
    <div className="gradient__landing">
      <img src={circles1} alt="" className="circles1" />
      <img src={phoneImage} alt="" className="phone" />
      <img src={monitorImage} alt="" className="monitor" />
    </div>
  </div>
  )
}

export default Banner