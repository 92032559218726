import React, { useState } from 'react';
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import { useFormik } from 'formik';
import Sign from '../../components/Sign/Sign';

interface ChangePasswordProps {
  setChangePassword: any;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({setChangePassword}) => {
  const auth = getAuth(firebaseApp);

  const [error, setError] = useState('');
  const [beError, setBeError] = useState('');

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    onSubmit: async (values) => {
      try {
        setError('');
        setBeError('');
        const user = auth.currentUser;

        if(values.newPassword !== values.confirmNewPassword) {
          return setError('Passwords do not match')
        }
  
        if(values.newPassword.length < 6 ) {
          return setError('Password should be at least 6 characters')
        }
  
        if (user === null) {
          return;
        }

        if (user.email === null) {
          return;
        }
    
        const credential = EmailAuthProvider.credential(
          user.email,
          values.currentPassword
        );

        const result = await reauthenticateWithCredential(
          user,
          credential
        );

        if(result && values.newPassword === values.confirmNewPassword) {
          updatePassword(user, values.newPassword);
          
        } 
        setPasswordReseted(true);
      } catch(err: any) {
        setBeError(err.code);
      }
    }
  });

  const [passwordReseted, setPasswordReseted] = useState(false);

  if (passwordReseted) {
    return (
      <Sign>
        <div className="flex items-center justify-center flex-col gap-7 p-8 rounded-md">
          <h2 className='text-center'>Password has been updated</h2>
          <button
            type="submit"
            onClick={() => {
              setChangePassword(false);
            }}>
            Go back
          </button>
        </div>
      </Sign>
    );
  }

  return (
    <Sign>
      <form onSubmit={formik.handleSubmit} className="change-password_form">
          <h2>Change password</h2>
          <label htmlFor="password">Current password</label>
          {
            beError === 'auth/wrong-password' ? (
              <div>
                <input
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  className='error-login'
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                />
                <span className='span__error-login'>Wrong Password</span>
              </div>
            ) : (
              <input
                id="currentPassword"
                name="currentPassword"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.currentPassword}
              />
            )
          }
          <label htmlFor="password">New password</label>
          {
            error ? (
              <div className='flex flex-col gap-7'>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  className='error-login'
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                />
                <label htmlFor="password">Confirm new password</label>
                <input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  className='error-login'
                  onChange={formik.handleChange}
                  value={formik.values.confirmNewPassword}
                />
                <span className='span__error-login'>{error}</span>
              </div>
            ) : (
              <div className='flex flex-col gap-7'>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                />
                <label htmlFor="password">Confirm new password</label>
                <input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmNewPassword}
                />
              </div>
            )
          }
          <div className='change-password_buttons'>
            <button
              type="submit"
              className='cursor-pointer'
            >
              Submit
            </button>
            <p
              onClick={() => {
                setChangePassword(false);
              }}
              className="cursor-pointer"  
            >
              Go back
            </p>
          </div>
        </form>
    </Sign>
  );
};

export default ChangePassword;
