import axios, { AxiosInstance } from 'axios';
import {FeedbackData, ServerRequest} from '../types/request.types';
export default class FeedbackService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async fetchFeedbacks(
    feedbackType: number,
    start = 0,
    limit = 5,
  ): Promise<ServerRequest<{
    pageSize: number,
    totalResults: number,
    totalPageNumber: number,
    currentPageNumber: number,
    data: FeedbackData[],
  }>> {
    const response = await this.httpClient.post(`/Feedback/filter-feedbacks`,
      {
        feedbackType,
        paginationCriteria: {
          start,
          limit,
        }
      });

    const formattedData = (response?.data?.responseObject?.data ?? []).map((el: any) => {
      const messageData = el.message.split('\n').map((s: string) => s.trim()).filter((s: string) => !!s);
      let timeStamp = messageData.find((d: string) => d.startsWith('Timestamp:'))
      timeStamp = timeStamp ? timeStamp.split('Timestamp:').pop().trim() : '';
      let mode = messageData.find((d: string) => d.startsWith('Mode:'))
      mode = mode ? mode.split('Mode:').pop().trim() : '';
      let temperature = messageData.find((d: string) => d.startsWith('Temperature:'))
      temperature = temperature ? temperature.split('Temperature:').pop().trim() : '';
      const message = el.message.includes('Error Description:')
        ? el.message.split('Error Description:').pop()
        : el.message.includes('Profile Feedback:')
          ? el.message.split('Profile Feedback:').pop()
          : el.message
      return ({
        ...el,
        timeStamp,
        mode,
        temperature,
        message
      })
    });
    return ({
      ...response.data,
      responseObject: {
        ...response.data.responseObject,
        data: formattedData
      },
    });
  }

}

export const feedbackService = new FeedbackService(axios);
