import React from 'react';
import { IProduct } from '../../types/product.types';
import Product from './Product';

interface IProductsProps {
  products: IProduct[];
  selectedProduct: IProduct | undefined;
  setSelectedProduct: (product: IProduct) => void;
}

const Products: React.FC<IProductsProps> = ({ products, selectedProduct, setSelectedProduct }) => {
  return (
    <div>
      {/* {products.filter((product) => product.unitAmount !== 200).map(
        (product) =>
          selectedProduct && (
              <Product
              numberOfProducts={products.length}
              key={product.productId}
              product={product}
              selected={selectedProduct.productId === product.productId}
              setSelected={setSelectedProduct}
              />
          )
      )} */}
      {/* {
        <div className="product__style">
          <div className="product__description offer">
            <p className={'introductory__offer'}>Introductory Offer - $29.99</p>
            <p className="regular__offer">(Regular price - $49.99)</p>
          </div>
        </div>
      } */}
      {products.map(
        (product) =>
          selectedProduct && (
            <Product
              numberOfProducts={products.length}
              key={product.productId}
              product={product}
              selected={selectedProduct.productId === product.productId}
              setSelected={setSelectedProduct}
            />
          )
      )}
    </div>
  );
};

export default Products;
