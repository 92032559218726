import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '../../firebase-config';
import Sign from '../../components/Sign/Sign';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import { authService } from '../../services/auth.service';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const returnUrl = query.get('returnUrl');

  const {
    state: { isLoggedIn }
  } = useUser();

  useEffect(() => {
    if (isLoggedIn) {
      if (returnUrl) {
        history.push(returnUrl);
      } else {
        history.push('/payment/try');
      }
    }
  }, [isLoggedIn]);

  const authentication = getAuth(firebaseApp);
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
      setError('');

      if (values.email && values.password) {
        try {
          await signInWithEmailAndPassword(authentication, values.email, values.password);

          if (authentication.currentUser === null) {
            return;
          }

          if (authentication.currentUser.displayName === null) {
            return;
          }

          const response = await authService.userExistsCheck(authentication.currentUser.uid);
          if (!response.responseObject) {
            const splitOnSpace = authentication.currentUser.displayName.split(' ');
            const firstName = splitOnSpace[0];
            const lastName = splitOnSpace[splitOnSpace.length - 1];

            const userResponse = await authService.createUserInfo(
              authentication.currentUser.uid,
              values.email,
              firstName,
              lastName
            );

            if (!userResponse.isSuccess) {
              setError(userResponse.errors[0]);
            }
          }
        } catch (error: any) {
          setError(error.code);
        }
      }
    }
  });

  return (
    <Sign>
      <form onSubmit={formik.handleSubmit} className="login">
        <h2>Sign In</h2>
        <p>Link your desktop / mobile App to your TruHu account.</p>
        <p>
          Don&apos;t have an account yet?{' '}
          <span>
            <Link to="/signup">Sign up</Link>
          </span>{' '}
          here.
        </p>
        <label>E-mail</label>
        {error === 'auth/user-not-found' ? (
          <div>
            <input
              id="email"
              name="email"
              className="error-login"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="name@email.com"
            />
            <span className="span__error-login">Email not found</span>
          </div>
        ) : (
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            required
            value={formik.values.email}
            placeholder="name@email.com"
          />
        )}
        <label>Password</label>
        {error === 'auth/wrong-password' ? (
          <div>
            <input
              type="password"
              name="password"
              className="error-login"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder="6+ Characters, 1 Capital letter"
            />
            <span className="span__error-login">Invalid Password</span>
          </div>
        ) : error === 'auth/too-many-requests' ? (
          <div>
            <input
              type="password"
              name="password"
              className="error-login"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder="6+ Characters, 1 Capital letter"
            />
            <span className="span__error-login">
              Access to this account has been temporarily disabled due to many failed login
              attempts. You can immediately restore it by resetting your password or you can try
              again later.
            </span>
          </div>
        ) : (
          <input
            type="password"
            name="password"
            id="password"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="6+ Characters, 1 Capital letter"
          />
        )}
        <p onClick={() => history.push('/reset-password')} className="cursor-pointer">
          <span>Forgot password?</span>
        </p>
        <button type="submit">Sign in</button>
      </form>
    </Sign>
  );
};

export default LoginForm;
