import React from 'react';
import whiteLogo from '../../assets/icons/Logo_white.svg';
import discord from '../../assets/icons/discord.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import youtube from '../../assets/icons/youtube.svg';
import { Link } from 'react-router-dom';
import '../../Styles/Footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <div className="content">
      <Link to="/">
        <img src={whiteLogo} alt="Logo" className="logo__foter" />
      </Link>
        <p>Good Color Made Easy</p>
        {/* <div className='social'>
          <a href="#"><img src={discord} alt="Discord Icon" /></a>
          <a href="#"><img src={facebook} alt="Facebook Icon" /></a>
          <a href="#"><img src={instagram} alt="Instagram Icon" /></a>
          <a href="#"><img src={youtube} alt="Youtube Icon" /></a>
        </div> */}
      </div>
      <div className="content">
        <p className="bold">About</p>
        <a href={process.env.REACT_APP_BLOG_URL + '/truhu-faqs/'}>FAQ</a>
        <Link to="/contact">Contact Us</Link>
        <a href={process.env.REACT_APP_BLOG_URL}>Blog</a>
      </div>
      <div className="content">
        <p className="bold">Information</p>
        <Link to="/tc">Terms and Conditions</Link>
        <Link to="/policy">Privacy Policy</Link>
        {/* <Link to='/help'>Help</Link> */}
      </div>
      <div className="copyright">
        <p>Copyright &copy; Seboya {new Date().getFullYear()}. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
