import React, { useState, useEffect } from 'react';
import Sign from '../../components/Sign/Sign';
import { authService } from '../../services/auth.service';
import { useUser } from '../../contexts/userContext';
import { Redirect } from 'react-router-dom';
import '../../Styles/Subscribed.scss';

const Subscribed: React.FC = () => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>();
  const {
    state: { trial, subscribed },
    dispatch
  } = useUser();

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      const response = await authService.getSubscriptionInfo();
      if (response) {
        setSubscriptionInfo(response);
      }
    };
    getSubscriptionInfo();
  }, []);

  if (!subscribed) {
    return <Redirect to="/" />;
  }

  return (
    <Sign>
      {trial ? (
        <div className="subscribe-page">
          <h2>Free Trial</h2>
          <p>
            Your free trial is now active until:{' '}
            <span className="text-bold">
              {subscriptionInfo?.responseObject.renewDate.split('T')[0]}
            </span>
          </p>
          <p>Download the app and start using TruHu now.</p>
          <button>Download</button>
        </div>
      ) : (
        <div className="subscribe-page">
          <h1 className="subscribed_title">Success!</h1>
          <p className="subscribed_congratulation">
            Congratulations – you are now subscribed to{' '}
            <span className="subscribed_truhuSpan">TruHu</span> color and can enjoy &quot;Good Color
            Made Easy&quot;
          </p>
          <p className="subscribed_text">
            Please visit the{' '}
            <a href="https://truhu.app/blog" className="blogRedirect">
              TruHu Blog
            </a>{' '}
            for answers <br />
            to your color-related questions.
          </p>
          <a href={'/download'}>
            <button>Get Started</button>
          </a>
        </div>
      )}
    </Sign>
  );
};

export default Subscribed;
