import React, {useState, useEffect} from "react";
import { authService } from '../../services/auth.service';
import { useUser } from '../../contexts/userContext';
import { stripeService } from '../../services/stripe.service';
import { Link } from "react-router-dom";
import whiteLogo from '../../assets/icons/Logo_white.svg';
import UserInfo from '../../components/UserInfo/UserInfo';
import '../../Styles/Contact.scss';

const SubscriptionPage = () => {

  const [subscriptionInfo, setSubscriptionInfo] = useState<any>();
  const { state: user } = useUser();
  const { state: {isCancelled, feedback, trial}, dispatch} = useUser();
  const [isApple, setIsApple] = useState(false);

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      const response = await authService.getSubscriptionInfo();
      if(response) {
        setSubscriptionInfo(response);
        if(response.responseObject.stripeCardPlaceHolder === '') {
          setIsApple(true);        
        } 
      }
    }
    getSubscriptionInfo();
  }, [])

  

  return (
    <UserInfo>
      {
        !subscriptionInfo ? (
          <h2>Loading...</h2>
        ) : (
          <div className="subscription__page">
            <div className="subscription__info">
              {
                trial ? (
                  <h2>Free trial</h2>
                ) : (
                  <h2>Subscription</h2>
                )
              }
              <p>{trial ? 'Trial start date:' : 'Start date:'} <span className="date-info">{subscriptionInfo?.responseObject.startDate.split('T')[0]}</span></p>
              <p>{trial ? 'Trial end date:' : 'End date:'} <span className="date-info">{subscriptionInfo?.responseObject.renewDate.split('T')[0]}</span></p>
              {
                trial ? (
                  <p>Would you like to leave us your feedback? Click <Link to='/feedback'><span className="text-underline">here</span></Link></p> 
                ) : null
              }
              {isCancelled ? (
                <div className="subscription-cancelled">
                  <p>Your subscription is cancelled, and will not be renewed at end date. You will still have access to TruHu until end date.</p><br></br>
                  {
                    !feedback ? (
                      <p>Would you like to leave us your feedback? Click <Link to='/feedback'><span className="text-underline">here</span></Link></p> 
                    ) : null
                  }
                </div>
              ) : (
                !trial || !isApple ? (
                  <p
                    onClick={async () => {
                          const serverResult = await stripeService.cancelSubscription(user.subscriptionId);

                          if (serverResult.responseObject && serverResult.responseObject.status === 'active') {
                            dispatch({type: 'set_request_cancelled', payload: true})
                          }
                        }}
                    className="cursor-pointer cancel-subscription"
                  >
                      Cancel subscription
                  </p>
                ) : (
                  null
                )
              )}

            </div>
            {
              !trial || !isApple ? (
                <div className="subscription__card-info">
                  <h2>Card</h2>
                  <div className="subscription__card">
                    <img src={whiteLogo} alt="" className="card-logo"/>
                    <p>**** **** **** {subscriptionInfo?.responseObject.stripeCardPlaceHolder}</p>
                    <p>{subscriptionInfo?.responseObject.stripeCardName}</p>
                  </div>
                  <a href={subscriptionInfo?.responseObject.stripePortalUrl}>
                  <button className="subscription__button">Manage card info</button>
                  </a>
                </div>
              ) : null
            }
          </div>
        )
      }
    </UserInfo>
  );
}

export default SubscriptionPage;