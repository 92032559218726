import React from 'react';
// --------components--------
import Layout from '../../components/Layout';
import Banner from '../../components/Landing/Banner';
import { GetStarted } from '../../components/Landing/GetStarted';
import Testimonials from '../../components/Landing/Testimonials';
// import Newsletter from "../../components/Newsletter/Newsletter";
// --------packages--------
import { Helmet } from 'react-helmet';
// ----------css-----------
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../../Styles/Landing.scss';
import AboutSection from '../../components/Landing/AboutSection';

const Landing = () => {
  return (
    <Layout>
      <div className="bgColor">
        <Helmet>
          <title>TruHu - Monitor Color Calibration Tool | Good Color Made Easy</title>
          <meta
            name="description"
            content="​​Calibrate your displays in under a minute with the TruHu App. Account for ambient lighting and Achieve color accuracy using just your phone's camera!"
          />
        </Helmet>
        <Banner />
        <GetStarted />
        <AboutSection />
        <Testimonials sectionClass="third-section__landing" />

        {/* <Newsletter /> */}
      </div>
    </Layout>
  );
};

export default Landing;
