import React from "react";
import Layout from '../../components/Layout';
import circles1_faq from '../../assets/icons/circles1_faq.svg';
import circles2_faq from '../../assets/icons/circles2_faq.svg';
import '../../Styles/Faq.scss';

const Faq = () => {
  return (
    <Layout>
      <div className='faq__page'>
          <img src={circles1_faq} alt="" className="circles1_faq"/>
          <img src={circles2_faq} alt="" className="circles2_faq"/>
        <h2>Frequently asked questions</h2>
        <div className="faq__questions">
          <div className="faq__section">
            <h4>1. How long does it take to calibrate my screen with TruHu?</h4>
            <p>Once the TruHu desktop and mobile apps are installed, it only takes 1-2 minutes to calibrate your screen.</p>
          </div>
          <div className="faq__section">
            <h4>2. What mobile devices and Operating Systems are supported by TruHu?</h4>
            <p>Computers:</p>
            <ul>
              <li>
                <p>macOS - High Sierra / 10.13 and later</p>
              </li>
              <li>
                <p>Windows - Win 7, Win 10 and later</p>
              </li>
            </ul>
            <p>Mobile handsets:</p>
            <ul>
              <li>
                <p>Apple - iPhone 7 and later</p>
              </li>
              <li>
                <p>Samsung - Galaxy Note 7 and later</p>
              </li>
            </ul>
            <p>Mobile Operating Systems:</p>
            <ul>
              <li>
                <p>iOS 10 and later</p>
              </li>
              <li>
                <p>Android 6.0.1 and later</p>
              </li>
            </ul>
          </div>
          <div className="faq__section">
            <h4>3. How do I disable some of the “auto-adjust” features on my Windows or Mac computer, such as Nightlight (Windows) and Night Shift or TrueTone (Mac)?</h4>
            <p>See our <span className="faq__link">Adjusting Brightness Settings</span> blog post.</p>
          </div>
          <div className="faq__section">
            <h4>4. How often should I calibrate my screen?</h4>
            <p>
            Thankfully, LCD screens are much more stable than their CRT predecessors, so the old recommendation of “calibrate your screen daily” no longer applies under most circumstances. So how often SHOULD I calibrate my screen? The primary part of your screen that changes over time is the backlight, so recommended calibration frequency is related to total hours the screen was used since the previous calibration. For professionals, who are assumed to have the screen on ~40 hours / week, the recommendation is to calibrate monthly. For more casual users (e.g., internet shoppers), you should be fine calibrating every 3-6 months. Thankfully, TruHu is easy to use and doesn&apos;t take much time, so no problem calibrating more frequently. Your license comes with an unlimited number of calibrations.
            </p>
          </div>
          <div className="faq__section">
            <h4>5. Can I Calibrate my phone or tablet with TruHu?</h4>
            <p>Currently, TruHu is designed to calibrate only monitors connected to a Mac or Windows-driven computer. But stay tuned…and let us know what you&apos;re looking for in the comments.</p>
          </div>
          <div className="faq__section">
            <h4>6. I have two side-by-side screens on my desk, and after calibrating both with TruHu they don&apos;t exactly match as closely as I hoped. What can I do about that?</h4>
            <p>This is one of the most challenging situations for calibration tools, since the human eye is so sensitive to subtle differences in a side-by-side setting. More sophisticated tools, ideally using a spectrophotometer, are better suited to tackle this problem.</p>
          </div>
          <div className="faq__section">
            <h4>7. What are those strange patterns I see on my phone when I&apos;m photographing my monitor with TruHu, and are they a problem?</h4>
            <p>These are known as <span className="faq__link">moire patterns</span> and are a result of an interaction between your phone&apos;s high resolution sensor geometry and the geometry of the screen it&apos;s photographing. This is not a problem for TruHu, though you can reduce moire by adjusting the distance/position of your phone.</p>
          </div>
          <div className="faq__section faq__section--last">
            <h4>8. What&apos;s the best Color Temperature setting for my screen?</h4>
            <p>The Color Temperature of white on your screen, also known as White Point, is one the settings you can pick in TruHu. This is really a matter of personal preference. The higher the color temperature, the bluer the white point. The most commonly used Color Temperature for screen calibration is 6500K. The sRGB (common for web imagery), AdobeRGB (common with Pro Photographers), and DCI-P3 (Cinema) color spaces all use 6500K as the Color Temperature for their White Point, making it a good choice under most circumstances. A white point of 5000K is commonly specified for commercial print applications in North America. This can result in a closer match between screen and paper white under recommended lighting.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Faq;