import axios, { AxiosInstance } from 'axios';
import { ServerRequest } from '../types/request.types';

export default class StripeService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async getAllPrices(): Promise<ServerRequest<any>> {
    const response = await this.httpClient.get('/Stripe/get-all-prices');
    return response.data;
  }

  async getFilteredPrices(): Promise<ServerRequest<any>> {
    const response = await this.httpClient.get('/Stripe/filter-prices');
    return response.data;
  }

  async createSubscription(
    customerId: string,
    paymentMethodId: string,
    priceId: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('/Stripe/create-subscription', {
      customerId,
      paymentMethodId,
      priceId
    });
    return response.data;
  }

  async createDemoSubscription(priceId: string, csvFile: File) {
    const formData = new FormData();
    formData.append('file', csvFile)

    const response = await this.httpClient.post(`/Stripe/generate-subscriptions?priceId=${priceId}`, formData , {
      headers: { 'Content-type': 'multipart/form-data' }, responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = 'generated_subscription_report.txt';
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      // Handle any errors
      console.error('Error occurred during file download:', error);
    });
  
  }

  async retryInvoice(
    customerId: string,
    paymentMethodId: string,
    invoiceId: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('/Stripe/retry-invoice', {
      customerId,
      paymentMethodId,
      invoiceId
    });
    return response.data;
  }

  async cancelSubscription(subscriptionId: string): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('/Stripe/cancel-subscription', {
      subscriptionId
    });
    return response.data;
  }

  async createCustomer(
    email: string,
    firstName: string,
    lastName: string
  ): Promise<ServerRequest<any>> {
    const response = await this.httpClient.post('/Stripe/create-customer', {
      email,
      firstName,
      lastName
    });
    return response.data;
  }
}

export const stripeService = new StripeService(axios);
