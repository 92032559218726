import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../firebase-config';

export const configureServices = (baseUrl = ''): void => {
  const auth = getAuth(firebaseApp);

  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post.Accept = 'application/json, text/plain,  multipart/form-data, */*';

  axios.interceptors.request.use(
    async (config) => {
      const token = await auth.currentUser?.getIdToken();
      if (config.headers) {
        config.headers.authorizationToken = token || '';
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
